<template>
	<transition name="topbar">
		<div class="top_bar" v-show="position > -1">
			<div class="top_bar_contents">
				<router-link to="/"><img src="./assets/sign.png"/></router-link>
				<div class="menus"><router-link to="/company">COMPANY</router-link> | <router-link to="/business">BUSINESS</router-link> | <router-link to="/product">PRODUCT</router-link> | <router-link to="/recruit">RECRUIT</router-link> | <router-link to="/contact">CONTACT</router-link></div>
			</div>
		</div>
	</transition>
</template>

<script>
window.addEventListener('load', function() {
	
});

export default {
	data: function(){
		return {
			position: -1
		}
	},
	methods: {
		link: function(key){
			changelink(key);
			return false;
		},
		handleResize: function(e){
			var bar = document.getElementsByClassName("top_bar");
			var sh = window.innerHeight;
			var top = ((sh - bar[0].clientHeight) / 2) - 200
			if(top < 0) top = 0;
			bar[0].style.top = top + "px";
			//bar[0].style.top = (((sh - bar[0].clientHeight) / 2) - 200) + "px";
		}
	},
	created: function(){
		window.addEventListener('resize', this.handleResize);
	},
	mounted: function(){
		var _self = this;
		
		this.handleResize();
		
		setTimeout(function () {
			_self.position = 0;
		}, 100);
	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
.top_bar {
	position: relative;
	background: rgba(255, 255, 255, 0.6);

	.top_bar_contents {
		font-size: @font-titlesize;
		margin-left: auto;
		margin-right: auto;
		max-width: 950px;

		.menus {
			float: right;
			margin-top: 19px;
			text-shadow:2px 2px 6px rgba(255, 255, 255, 0.6), -2px -2px 6px rgba(255, 255, 255, 0.6), 2px -2px 6px rgba(255, 255, 255, 0.6), -2px 2px 6px rgba(255, 255, 255, 0.6);
		}

		img {
			width: 200px;
		}
	}
}

.topbar-enter-active, .topbar-leave-active {
	transition: all .7s ease;
	-webkit-transition: all .7s ease;
}
.topbar-enter, .topbar-leave-to {
	top: -200;
	opacity: 0;
}
/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.top_bar {
		.top_bar_contents {
			max-width: 650px;
		}

		.menus {
			float: none;
			font-size: 14px;
		}		
	}


}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.top_bar {
		height: 95px;

		.top_bar_contents {
			max-width: 350px;
		}

		.menus {
			float: none;
			font-size: 12px;
		}
	}
}
</style>
