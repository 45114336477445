<template>
	<div class="topic tbl">
		<div class="tblrow">
			<div class="tblcell topic_date">{{ item.date }}</div>
			<div class="tblcell topic_title" v-if="(item.link =='')||(item.link == undefined)">{{ item.title }}</div>
			<div class="tblcell topic_title" v-if="(item.link !='')&&(item.link != undefined)"><router-link :to="item.link">{{ item.title }}</router-link></div>
		</div>
	</div>
</template>

<script>
export default {
	props:["item"]
};
</script>

<style lang="less" scoped>
@import "./less/const.less";
.news_info {
	.topic {
		width: 100%;
		padding: 21px 0;
		border-bottom: 1px solid @border-def;
	}

	.topic:first-child {
		border-top: 1px solid @border-def;
	}

	.topic_date {
		width: 160px;
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.news_info {
		.topic_date {
			width: 120px;
		}
	}
}
</style>
