<template>
	<div id="top-page">
		<top-bar></top-bar>
		<slogan></slogan>
		<product-carousel></product-carousel>
		<div class="downBtn"><span class="downBtnAnk" @click="scrollAnswer('info_area')"><icon name="angle-down" scale="5" color="#FFFFFF"></icon></span></div>
		<div class="contentsArea">
			<a name="info_area" style="position:relative; top:40px;"></a>
			<news-info></news-info>
			<div class="p_mark"><a href="https://privacymark.jp/" target="_brank"><img style="width:8rem" src="./assets/pmark.png"/></a></div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import topbar from "./cp_top_bar.vue"
import slogan from "./cp_slogan.vue"
import productcarousel from "./cp_pruduct_carousel.vue"
import newsinfo from "./cp_news_info.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'topPage',
	components: {
		"top-bar": topbar,
		"slogan": slogan,
		"product-carousel": productcarousel,
		"news-info": newsinfo,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	data: function(){
		return {
			isSmartPhone: false
		}
	},
	methods: {
		handleResize: function(e) {
			if (window.matchMedia('(max-width:480px)').matches) {
				// SPの処理
				this.isSmartPhone = true;
			} else if (window.matchMedia('(max-width:800px)').matches) {
				// TABの処理
				this.isSmartPhone = false;
			} else {
				// PCの処理
				this.isSmartPhone = false;
			}
		},
		scrollAnswer: function(name){
			var element;
			if ('scrollingElement' in document) {
				element = document.scrollingElement;
			} else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
				element = document.body;
			} else {
				element = document.documentElement;
			}
			var anc = document.getElementsByName(name)[0];
			var rect = anc.getBoundingClientRect();
			var top = rect.top + element.scrollTop - 60;
			this.scrollTo(top, 300);
		},
		scrollTo: function(to, duration) {
			var element;
			if ('scrollingElement' in document) {
				element = document.scrollingElement;
			} else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
				element = document.body;
			} else {
				element = document.documentElement;
			}
			if (duration <= 0) return;
			var difference = to - element.scrollTop;
			var perTick = difference / duration * 10;
			var _self = this;
			
			setTimeout(function () {
				element.scrollTop = element.scrollTop + perTick;
				if (element.scrollTop === to) return;
				_self.scrollTo(to, duration - 10); // 再帰で実行
			}, 10);
		}
	},
	created: function(){
		window.addEventListener('resize', this.handleResize);
	},
	mounted: function(){
		document.body.classList.add("body");
		
		this.handleResize();

		var ca = document.getElementsByClassName("contentsArea")[0];
		var xx = 55;
		if (this.isSmartPhone) {
			xx = 145;
		}
		ca.style.marginTop = (window.innerHeight - xx - 165) + "px";

		var btn = document.getElementsByClassName("downBtn")[0];
		var sh = window.innerHeight;
		btn.style.top = (window.innerHeight - xx - 155) + "px";
	}
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
.body {
	width: 100%;
	/* 画像ファイルの指定 */
	background-image: url(./assets/green.jpg);
	/* 画像を常に天地左右の中央に配置 */
	background-position: center center;
	/* 画像をタイル状に繰り返し表示しない */
	background-repeat: no-repeat;
	/* コンテンツの高さが画像の高さより大きい時、動かないように固定 */
	background-attachment: fixed;
	/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
	background-size: cover;
	
	
	&::before {
		content: "";
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100vh;
	}
}

#top-page {
	.downBtn {
		font-size: @font-lagesize;
		position: relative;
		max-width: 950px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		
		.downBtnAnk {
			cursor: pointer;
		}
	}
	.contentsArea {
		background-color: @bgcolor-white;
		padding: 22px 0;
	}
}

.p_mark {
	width: 950px;
	margin-top: 22px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.body {
		background: none;
		
		&::before {
			content: "";
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100vh;
			background: url(./assets/green.jpg) center/cover no-repeat;
			-webkit-background-size: cover;/*Android4*/
		}
	}
	
	.p_mark {
		width: 650px;
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.body {
		background: none;
		
		&::before {
			content: "";
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100vh;
			background: url(./assets/green.jpg) center/cover no-repeat;
			-webkit-background-size: cover;/*Android4*/
		}
	}
	
	.p_mark {
		width: 300px;
	}
}
</style>
