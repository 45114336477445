<template>
	<div>
		<form id="contact_form">
			<a name="errormes"></a>
			<div v-if="errorFlg" class="error_mess requiredText" v-html="errorMess"></div>
			<div v-if="!isSmartPhone" class="tbl contactArea">
				<div class="tblrow">
					<div class="tblcell reqcell compname_head" style="width:22px;">※</div>
					<div class="tblcell titlecell compname_head">
						会社／団体名
					</div>
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="compname">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell reqcell section_head" style="width:22px"></div>
					<div class="tblcell titlecell section_head">
						所属部署名
					</div>
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="section">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell reqcell worktype_head" style="width:22px"></div>
					<div class="tblcell titlecell worktype_head">
						業種
					</div>
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="worktype">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell reqcell name_head" style="width:22px">※</div>
					<div class="tblcell titlecell name_head">
						お名前
					</div>
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="name">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell reqcell kana_head" style="width:22px">※</div>
					<div class="tblcell titlecell kana_head">
						フリガナ
					</div>
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="kana">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell reqcell mail_head" style="width:22px">※</div>
					<div class="tblcell titlecell mail_head">
						メールアドレス
					</div>
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="mail">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell reqcell address_head" style="width:22px">※</div>
					<div class="tblcell titlecell address_head">
						ご住所
					</div>
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="address">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell reqcell phone_head" style="width:22px">※</div>
					<div class="tblcell titlecell phone_head">
						電話番号
					</div>
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="phone">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell reqcell title_head" style="width:22px">※</div>
					<div class="tblcell titlecell title_head">
						お問い合わせの種類
					</div>
					<div class="tblcell valcell" style="padding:12px 6px;">
						<select class="selectfield" v-model="title">
							<option selected>商品について</option>
							<option >資料請求</option>
							<option>求人について</option>
							<option>その他</option>
						</select>
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell reqcell message_head" style="width:22px; vertical-align:top; padding-top:8px">※</div>
					<div class="tblcell titlecell message_head" style="vertical-align:top">
						お問い合わせ内容<br><br>
						<span class="smallsize" style="font-weight:normal">・資料請求時は商品名をご明記願います。</span>
					</div>
					<div class="tblcell valcell">
						<textarea class="textareafield" v-model="message"></textarea>
					</div>
				</div>
			</div>
			<div v-if="isSmartPhone" class="tbl contactArea">
				<div class="tblrow">
					<div class="tblcell titlecell compname_head">※会社／団体名</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="compname">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell titlecell section_head">所属部署名</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="section">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell titlecell worktype_head">業種</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="worktype">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell titlecell name_head">※お名前</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="name">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell titlecell kana_head">※フリガナ</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="kana">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell titlecell mail_head">※メールアドレス</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="mail">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell titlecell address_head">※ご住所</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="address">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell titlecell phone_head">※電話番号</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell">
						<input type="text" class="textfield" v-model="phone">
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell titlecell title_head">※お問い合わせの種類</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell" style="padding:12px 6px;">
						<select class="selectfield" v-model="title">
							<option selected>商品について</option>
							<option >資料請求</option>
							<option>求人について</option>
							<option>その他</option>
						</select>
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell titlecell message_head">
						※お問い合わせ内容<br>
						<span class="smallsize" style="font-weight:normal">・資料請求時は商品名をご明記願います。</span>
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell valcell">
						<textarea class="textareafield" v-model="message"></textarea>
					</div>
				</div>
			</div>
			<div class="tbl submit_area">
				<div class="tblrow">
					<div class="tblcell reqcell" style="padding:18px 6px">
						<input type="checkbox" v-model="privacy"/>「<router-link to="/privacy"><span style="font-weight:bold; color:#49AB44">プライバシーポリシー</span></router-link>」に同意します。
					</div>
				</div>
				<div class="tblrow">
					<div class="tblcell" style="text-align:center">
						<button v-if="$store.state.contact" class="submitBtn" v-on:click="checkval">送信</button>
						<button v-if="!($store.state.contact)" class="submitBtn" style="cursor:default" disabled>現在ご利用になれません</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	data: function(){
		return {
			cgipath: "",
			compname: "",
			section: "",
			worktype: "",
			name: "",
			kana: "",
			mail: "",
			address: "",
			phone: "",
			title: "",
			message: "",
			privacy: false,
			errorFlg: false,
			errorMess: "",
			isSmartPhone: false
		}
	},
	methods: {
		checkval: function(e) {
			var err = false;
			var noval = false;
			var formatFlg = false;
			
			// 必須チェック
			if ((this.compname == "")||(this.compname == undefined)) {
				err = true;
				noval = true;
				Array.from(document.getElementsByClassName("compname_head")).forEach( (el) => {
					el.classList.add("requiredText");
				});
			}
			if ((this.name == "")||(this.name == undefined)) {
				err = true;
				noval = true;
				Array.from(document.getElementsByClassName("name_head")).forEach( (el) => {
					el.classList.add("requiredText");
				});
			}
			if ((this.kana == "")||(this.kana == undefined)) {
				err = true;
				noval = true;
				Array.from(document.getElementsByClassName("kana_head")).forEach( (el) => {
					el.classList.add("requiredText");
				});
			}
			if ((this.mail == "")||(this.mail == undefined)) {
				err = true;
				noval = true;
				Array.from(document.getElementsByClassName("mail_head")).forEach( (el) => {
					el.classList.add("requiredText");
				});
			}
			if ((this.address == "")||(this.address == undefined)) {
				err = true;
				noval = true;
				Array.from(document.getElementsByClassName("address_head")).forEach( (el) => {
					el.classList.add("requiredText");
				});
			}
			if ((this.phone == "")||(this.phone == undefined)) {
				err = true;
				noval = true;
				Array.from(document.getElementsByClassName("phone_head")).forEach( (el) => {
					el.classList.add("requiredText");
				});
			}
			if ((this.title == "")||(this.title == undefined)) {
				err = true;
				noval = true;
				Array.from(document.getElementsByClassName("title_head")).forEach( (el) => {
					el.classList.add("requiredText");
				});
			}
			if ((this.message == "")||(this.message == undefined)) {
				err = true;
				noval = true;
				Array.from(document.getElementsByClassName("message_head")).forEach( (el) => {
					el.classList.add("requiredText");
				});
			}
			
			var mails = this.mail.split("@");
			var pattern  = /[\x00-\x1F\x7F-\x9F]/;
			var pattern2 = /[^\u0020-\u007E]/;
			if (mails.length != 2) {
				err = true;
				formatFlg = true;
			}
			if ((mails[0] == "")||(mails[0] == undefined)) {
				err = true;
				formatFlg = true;
			}
			if ((mails[1] == "")||(mails[1] == undefined)) {
				err = true;
				formatFlg = true;
			}
			if ((pattern.test(mails[0]))||(pattern.test(mails[1]))) {
				err = true;
				formatFlg = true;
			}
			if ((pattern2.test(mails[0]))||(pattern2.test(mails[1]))) {
				err = true;
				formatFlg = true;
			}
			
			if (err) {
				if (noval) {
					this.errorMess = "※必須項目に未記入の項目があります。<br>";
				}
				if (formatFlg) {
					Array.from(document.getElementsByClassName("mail_head")).forEach( (el) => {
						el.classList.add("requiredText");
					});
					this.errorMess = this.errorMess + "※メールアドレスを確認して下さい。<br>";
				}
				this.errorFlg = true;
				
				var element;
				if ('scrollingElement' in document) {
					element = document.scrollingElement;
				} else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
					element = document.body;
				} else {
					element = document.documentElement;
				}
				var anc = document.getElementsByName('errormes')[0];
				var rect = anc.getBoundingClientRect();
				var top = rect.top + element.scrollTop - 60;
				this.scrollTo(top, 300);
				
				return false;
			}
			
			if ((this.privacy == false)||(this.privacy == undefined)) {
				alert("プライバシーポリシーにご同意頂き送信をお願い致します。");
				return false;
			}
			
			this.submit(e);
			return true;
		},
		submit: function(e) {
			if (!confirm("内容が宜しければ送信します。\n\n送信しますか？")) {
				return false;
			}
			
			var btm = document.getElementsByClassName('submitBtn')[0];
			btm.disabled = "true";
			
			let res = axios.post("../cgi-bin/contact.py", {
				"compname": this.compname,
				"section": this.section,
				"worktype": this.worktype,
				"name": this.name,
				"kana": this.kana,
				"mail": this.mail,
				"address": this.address,
				"phone": this.phone,
				"title": this.title,
				"message": this.message
			}).then(res => {
				//console.log(res);
				btm.disabled = "";
				if (res.data.status != "ok") {
					//console.log(res.data.error);
				} else {
					//console.log("OK");
					this.$router.push({ path:'/contact/rev' });
				}
			}).catch(e => {
				//console.log(e);
				btm.disabled = "";
			});
		},
		handleResize: function(e) {
			if (window.matchMedia('(max-width:480px)').matches) {
				// SPの処理
				this.isSmartPhone = true;
			} else if (window.matchMedia('(max-width:800px)').matches) {
				// TABの処理
				this.isSmartPhone = false;
			} else {
				// PCの処理
				this.isSmartPhone = false;
			}
		},
		scrollTo: function(to, duration) {
			var element;
			if ('scrollingElement' in document) {
				element = document.scrollingElement;
			} else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
				element = document.body;
			} else {
				element = document.documentElement;
			}
			if (duration <= 0) return;
			var difference = to - element.scrollTop;
			var perTick = difference / duration * 10;
			var _self = this;
			
			setTimeout(function () {
				element.scrollTop = element.scrollTop + perTick;
				if (element.scrollTop === to) return;
				_self.scrollTo(to, duration - 10); // 再帰で実行
			}, 10);
		}
	},
	created: function(){
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('load', (e) => {
			this.handleResize(e);
		});
	},
	mounted: function(){
		if (this.$route.query.flg == "1") {
			this.title = "資料請求";
		} else if (this.$route.query.flg == "2") {
			this.title = "求人について";
		}
	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
#contact_form {
	.requiredText {
		color: red;
	}
	
	.error_mess {
		width: 600px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 0 22px;
		font-weight: bold;
	}
	
	.contactArea {
		width: 600px;
		margin-left: auto;
		margin-right: auto;
		
		.reqcell {
			border-bottom: 1px solid @border-def;
		}
		
		.titlecell {
			width: 180px;
			padding: 8px 6px;
			font-weight: bold;
			border-bottom: 1px solid @border-def;
		}
		
		.valcell {
			padding: 8px 6px;
			border-bottom: 1px solid @border-def;
		}
		
		.tblrow {
			&:first-child {
				.reqcell, .titlecell, .valcell {
					border-top: 1px solid @border-def;
				}
			}
		}
		
		.textfield {
			width: 398px;
			padding: 6px;
			font-size: 13.3px;
		}
		
		.selectfield {
			width: 398px;
			padding: 6px;
			font-size: 13.6px;
		}
		
		.textareafield {
			width: 398px;
			height: 200px;
			padding: 6px;
			font-size: 13.3px;
		}
		
		.smallsize {
			font-size: @font-smallsize;
		}
		
		textarea {
			resize: none;
		}
	}
	
	.submit_area {
		width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.submitBtn {
		font-size: @font-lagesize;
		padding: 8px 24px;
		border-radius: 8px;
		border: 1px solid @border-def;
		background-color: #308CE0;
		color: @white-textcolor;
		cursor: pointer;
	}

}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#contact_form {
		.contactArea {
			width: 600px;
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#contact_form {
		.contactArea {
			width: 300px;
			
			.titlecell {
				width: 300px;
				padding: 8px 6px 0;
				border-bottom: unset;
			}
			
			.textfield {
				width: 274px;
				padding: 0 6px 6px;
				font-size: 13.3px;
			}
			
			.selectfield {
				width: 274px;
				padding: 0 6px 6px;
				font-size: 13.6px;
			}
			
			.textareafield {
				width: 274px;
				height: 160px;
				padding: 0 6px 6px;
				font-size: 13.3px;
			}
		}
		
		.submit_area {
			width: 300px;
		}
	}
}
</style>
