<template>
	<div id="contact-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px"></div>
		<div class="content_area">
			<div class="sub_title">お問い合わせ</div>
			<hr></hr>
			<div style="margin-bottom:32px">
				お問い合わせは、速やかに回答させて頂きますが、内容によっては数日お待たせする場合が御座いますので、予めご了承願います。<br>
				土曜日、日曜日・祝日など当社休業日および営業時間外に頂いたお問い合わせにつきましては、翌営業日以降の受付とさせて頂きますので
				ご了承願います。<br>（受付時間：平日9:00～17:30）<br>
				回答はメールにて行わせて頂きますが、お問い合わせ頂きました内容によっては、お電話にて直接ご連絡させて頂く場合があります。<br>
				なお、ご要望に沿えない場合も御座いますので予めご了承ください。<br>
				当社の許可なく、回答内容の一部または全部を転載、二次利用することはご遠慮ください。<br>
				「<router-link to="/privacy"><span style="font-weight:bold; color:#49AB44">プライバシーポリシー</span></router-link>」を
				必ずお読みいただき、ご同意いただいた上で送信をお願いいたします。<br>
				<br>※は必須事項です。必ずご記入ください。<br>
			</div>
			<contact-frame></contact-frame>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import contact from "./cp_contact.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'contactPage',
	components: {
		"header-bar": headerBar,
		"site-map": sitemap,
		"copy-right": copyright,
		"contact-frame": contact,
		"gotop-button": gotop
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#contact-page {
	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/privacy.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}
	
	.content_area {
		width: 950px;
		margin: 24px auto;
		line-height: 20.9px;
		
		.sub_title {
			.section_bar;
			font-size: @font-titlesize;
			font-weight: bold;
			padding-left: 6px;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#contact-page {
		.content_area {
			width: 650px;
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#contact-page {
		.main_area {
			height: 140px;
		}
		
		.content_area {
			width: 300px;
		}
	}
}
</style>
