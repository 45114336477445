<template>
	<transition name="goto">
		<div class="gotopButton" v-if="position > 250" @click="toTop()">
			<div><img src="./assets/gotop.png" /></div>
		</div>
	</transition>
</template>

<script>
export default {
	data: function(){
		return {
			position: 0
		}
	},
	methods: {
		toTop: function(){
			this.scrollTo(0, 300);
		},
		scrollTo: function(to, duration) {
			var element;
			if ('scrollingElement' in document) {
				element = document.scrollingElement;
			} else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
				element = document.body;
			} else {
				element = document.documentElement;
			}
			if (duration <= 0) return;
			var difference = to - element.scrollTop;
			var perTick = difference / duration * 10;
			var _self = this;
			
			setTimeout(function () {
				element.scrollTop = element.scrollTop + perTick;
				if (element.scrollTop === to) return;
				_self.scrollTo(to, duration - 10); // 再帰で実行
			}, 10);
		},
		handleScroll: function(e){
			this.position = document.documentElement.scrollTop || document.body.scrollTop;
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
.gotopButton {
	position: fixed;
	right: 0;
	bottom:0;
	width: 80px;
	text-align: center;
	vertical-align: middle;
	height: 60px;
	
	div {
		font-size: @font-lagesize;
		font-weight: bold;
		cursor: pointer;
	}
}

.goto-enter-active, .goto-leave-active {
	transition: all .7s ease;
	-webkit-transition: all .7s ease;
}
.goto-enter, .goto-leave-to {
	bottom: -60px;
	opacity: 0;
}
</style>
