// MedicalCube 紹介ページ
<template>
	<div class="busi-medcube">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<div class="navi_area"><span class="navi_bar_link"><router-link to="/">TOP</router-link> &gt; <router-link to="/product">PRODUCT</router-link> &gt;</span> Med@Cube</div>
			<div class="bgImage_area"></div>
			<div class="conetnts_area">
				<biz-menu></biz-menu>
				<div class="description">
					MedicalCubeは、電子カルテや各種病院システムから取得した情報を、ベンダーフリーな高性能リポジトリに構築し、<br>
					そのデータの二次活用として、診療・研究・教育の支援、障害時対応・支援、地域連携支援をご提供するシステムです。
				</div>
				<a name="outline" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div class="sub_title">蓄積データの２次利用をサポート</div>
				<div class="tbl" style="width:100%; margin-top:32px;">
					<div class="tblrow">
						<div class="tblcell" style="text-align:center; position:relative">
							<img class="contentImg1" src="./assets/medcube_1.jpg" />
						</div>
					</div>
				</div>
				<div class="sub_title" style="margin-top:80px;">柔軟な接続環境の実装と長期運用</div>
				<div class="tbl" style="width:100%; margin-top:32px;">
					<div class="tblrow">
						<div class="tblcell" style="text-align:center; position:relative">
							<img class="contentImg2" src="./assets/medcube_2.jpg" />
						</div>
					</div>
				</div>
				<a name="function" style="position:relative; top:20px"></a>
				<div style="position:relative; height:40px"></div>
				<div class="sub_title">発見 - 自然言語解析 -</div>
				<div class="search_a_back">
					<div class="disc1">
						InterSystems iKnow テクノロジを採用し、非構造化データのテキスト解析より、着目したワードに関する診療データの動きを視覚的に表現。<br>
						ワードとワードの関連性や、出現頻度の変化など、これまで具体化が難しかった情報を掘り出します。<br>
						<img src="./assets/medcube_2.png" />
					</div>
				</div>
				<div class="sub_title" style="margin-top:83px">簡単 - 誰もが使える -</div>
				<div class="search_b_back">
					<div class="disc1">
						クエリはユーザーやグループ毎などで利用・管理が行え、クエリの作成も専門的な知識が無くても扱いやすい設計になっています。<br>
						もちろん専門的に扱うための機能も備わっています。<br>
					</div>
				</div>
				<a name="request" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div class="request_area">
					<div class="titlesize">資料請求</div>
					<div style="margin-top:32px; line-height: 20.9px">診療支援データウェアハウス MedicalCube に関する資料のお求めや、ご質問等につきましては、<br>以下よりお気軽にお問い合わせ下さい。</div>
					<div class="titlesize button_style" style="margin-top:32px"><router-link to="/contact?flg=1">資料請求はこちら</router-link></div>
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import bizMenu from "./cp_businessMenu.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'busiMedcubePage',
	components: {
		"header-bar": headerBar,
		"biz-menu": bizMenu,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
.busi-medcube {
	.navi_area {
		width: 950px;
		margin: 22px auto 16px;
	}

	.bgImage_area {
		height: 160px;
		background-image: url(./assets/medcube_top2.jpg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.conetnts_area {
		width: 950px;
		margin: 22px auto;
		
		.description {
			text-align: center;
			font-size: @font-lagesize;
			line-height: 23px;
			margin: 32px 0;
		}
		
		.sub_title {
			.section_bar;
			font-weight: bold;
			font-size: @font-titlesize;
			margin-top: 64px;
			text-shadow:2px 2px 2px @shadow-color;
			padding: 6px;
		}
		
		.titlesize {
			font-size: @font-titlesize;
		}
		
		.holbar {
			padding-bottom: 6px;
			border-bottom: 1px solid #A9A9A9;
		}
		
		.contentImg1 {
		}
		
		.contentImg2 {
		}
		
		.search_a_back {
			position: relative;
			height: 380px;
			margin: 32px 0 180px;
			background-image: url(./assets/wood-cube.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			
			.disc1 {
				position: absolute;
				width: 560px;
				height: 180px;
				right: 32px;
				top: 32px;
				padding: 12px;
				border-radius: 18px;
				background-color: rgba(0, 0, 0, 0.3);
				color: @white-textcolor;
				box-shadow: 2px 2px 10px 2px @shadow-color;
				line-height: 23px;
				font-size: @font-lagesize;
				
				img {
					margin-left:-230px;
				}
			}
		}
		
		.search_b_back {
			position: relative;
			height: 380px;
			margin: 32px 0 84px;
			background-image: url(./assets/medcube2.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			
			.disc1 {
				position: absolute;
				width: 560px;
				height: 180px;
				right: 32px;
				bottom: 32px;
				padding: 12px;
				border-radius: 18px;
				background-color: rgba(0, 0, 0, 0.3);
				color: @white-textcolor;
				box-shadow: 2px 2px 10px 2px @shadow-color;
				line-height: 23px;
				font-size: @font-lagesize;
			}
		}
		
		.request_area {
			margin: 64px;
			text-align: center;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.busi-medcube {
		.navi_area {
			width: 650px;
		}
		
		.bgImage_area {
			height: 103px;
		}
		
		.conetnts_area {
			width: 650px;
			
			.contentImg1 {
				width: 600px;
				height: 257.5px;
			}
			
			.contentImg2 {
				width: 600px;
				height: 321.9px;
			}
			
			.search_a_back {
				.disc1 {
					img {
						margin-left:-34px;
					}
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.busi-medcube {
		.navi_area {
			width: 300px;
			margin-top: 90px;
		}
		
		.bgImage_area {
			height: 103px;
		}
		
		.conetnts_area {
			width: 300px;
			
			.contentImg1 {
				width: 300px;
				height: 128.7px;
			}
			
			.contentImg2 {
				width: 300px;
				height: 160.9px;
			}
			
			.search_a_back {
				height: 140px;
				
				.disc1 {
					font-size: @font-sssize;
					line-height: 14px;
					width: 240px;
					height: 100px;
					right: 8px;
					
					img {
						width: 240px;
						height: 156.9px;
						margin-left:-34px;
					}
				}
			}
			
			.search_b_back {
				height: 140px;
				
				.disc1 {
					font-size: @font-sssize;
					line-height: 14px;
					width: 240px;
					height: 80px;
					right: 26px;
					bottom: -30px;
				}
			}
			
			.request_area {
				margin: 64px 0;
				text-align: center;
			}
		}
	}
}
</style>
