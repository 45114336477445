<template>
	<div id="company-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<comp-menus :activeNode="code"></comp-menus>
		</div>
		<div class="content_area">
			<div class="sub_title bold_title" style="margin-bottom:10px">ごあいさつ</div>
			<div>
			　当社は、1999年に創業し、お客様に喜んでいただけるシステム、実務に合わせた使いやすいシステムを目指し、長年にわたってお客様と協力しながらシステム開発を行ってまいりました。<br>
			ビッグデータやAIを活用する時代を迎え、データの持つ重要性がこれまで以上に大きくなっております。<br>
			現代社会において情報システムは、データを「収集」、「蓄積」、「処理」、「加工」することに加えて、データを「連携」、「統合」、「2次利用」ためのテクノロジーとして不可欠な存在となっており、さらなる進化が求められております。<br>
			　当社は、情報システムにおけるアプリケーション開発のみならず、データの利活用を念頭においた連携・統合をはかり、お客様とともにデータのもつ「力」「可能性」でお客様のビジネスへの貢献、さらには、より良い未来社会の実現のために社会課題の解決に寄与したいと考えています。
			</div>
			<table class="companyTable largesize">
				<tr>
					<td>
						<span class="sub_title bold_title">概要</span>
						<table class="company_info_grid">
							<tr>
								<td class="title_cell">社名</td><td class="disc_cell">データキューブ株式会社</td>
							</tr>
							<tr>
								<td class="title_cell">設立</td><td class="disc_cell">1999年07月15日</td>
							</tr>
							<tr>
								<td class="title_cell">資本金</td><td class="disc_cell">2,000万円</td>
							</tr>
							<tr>
								<td class="title_cell">代表者</td><td class="disc_cell">代表取締役　上村　慎一郎</td>
							</tr>
							<tr>
								<td class="title_cell">正社員</td><td class="disc_cell">26名（男性21名、女性5名）　※2023年1月1日現在</td>
							</tr>
							<tr>
								<td class="title_cell">売上高</td><td class="disc_cell">3.3億円</td>
							</tr>
							<tr>
								<td class="title_cell">本社所在地</td><td class="disc_cell">
                  〒812-0011<br>
                  福岡市博多区博多駅前1丁目14番16号<br>
                  博多駅前センタービル7階<br>
                  電話：092-433-3366　FAX：092-433-3376
								</td>
							</tr>
							<tr>
								<td class="title_cell">事業内容</td><td class="disc_cell">ソフトウエア開発、ソフトウエア販売</td>
							</tr>
							<tr>
								<td class="title_cell">事業概要</td><td class="disc_cell">
									当社は、医療分野を中心としたシステム受託開発、自社システム導入・開発を主たる事業としております。<br>
									取引先は東北から沖縄まで展開をしており、大学病院からクリニックまでの幅広い規模の医療機関、大手医薬品卸、大手医療機器卸などが取引先です。<br>
									常に顧客視点での医療分野のDX化のお手伝いとより豊かな社会づくりに貢献することを意識し事業展開しています。<br>
									当社はCach&eacute;/M言語でのシステム開発を得意とし、その技術を元にした医療情報関連のシステム開発対応が可能なことが特徴です。	
								</td>
							</tr>
							<tr>
								<td class="title_cell">営業所</td><td class="disc_cell">沖縄、鹿児島、宮崎、千葉</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import compMenu from "./cp_compMenu.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'companyPage',
	components: {
		"header-bar": headerBar,
		"comp-menus": compMenu,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	data: function(){
		return {
			code: "1"
		}
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#company-page {
	font-size: @font-lagesize;

	table {
		border-collapse: collapse;
		border-spacing: 0;
		font-size: @font-defailtsize;
	}

	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/light.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}

	.content_area {
		width: 950px;
		margin: 24px auto;
		line-height: 20.9px;

		.largesize {
			font-size: @font-lagesize;
		}

		.companyTable {
			width: 950px;
			margin-top: 36px;
		}

		.sub_title {
			.section_bar;
			font-weight: bold;
			padding-left: 6px;
		}
	}

	.company_info_grid {
		width: 100%;
		margin: 12px 0;

		tr {
			&:first-child {
				.title_cell, .disc_cell {
					border-top: 1px solid @border-def;
				}
			}
		}

		.title_cell {
			width: 150px;
			padding: 18px 12px;
			vertical-align: middle;
			background-color: @gbcolor-title;
			border-bottom: 1px solid @border-def;
		}

		.disc_cell {
			padding: 18px 12px;
			vertical-align: middle;
			border-bottom: 1px solid @border-def;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#company-page {
		.content_area {
			width: 650px;

			.companyTable {
				width: 650px;
			}
		}

		.company_info_grid {
			.title_cell {
				width: 120px;
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#company-page {
		.main_area {
			height: 140px;
		}

		.content_area {
			width: 300px;

			.companyTable {
				width: 300px;
			}
		}

		.company_info_grid {
			font-size: 12px;

			.title_cell {
				width: 60px;
			}
		}
	}
}
</style>
