// Warabeeの紹介ページ
<template>
	<div class="busi-warabee">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<div class="navi_area"><span class="navi_bar_link"><router-link to="/">TOP</router-link> &gt; <router-link to="/product">PRODUCT</router-link> &gt;</span> Warabee</div>
			<div class="bgImage_area"></div>
			<div class="conetnts_area">
				<biz-menu></biz-menu>
				<div class="description">
					患者様一人ひとりの月経周期に合わせたカレンダーに沿って時系列で治療計画を作成し、<br>
					診療行為を入力していくことで、不妊治に特化した療業務プロセスの効率化とデータ管理を行うシステムです。
				</div>
				<a name="outline" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div>
					<span class="sub_title section_bar" style="padding:6px">患者様の満足度向上に</span>
					<div class="wbpanel1">
						<div class="disc1" style="margin: 32px 0; line-height:20.9px">
							卵胞の発育、あるいは胚の状態をカレンダー形式による時系列でお見せしながら、患者様とイメージとして情報共有できるため、
							患者様の満足度の向上につながります。<br>
							<img class="img1" src="./assets/warabee1.png" />
						</div>
					</div>
					<span class="sub_title section_bar" style="padding:6px">快適な操作感</span>
					<div class="wbpanel2">
						<div class="disc1" style="margin: 32px 0; line-height:20.9px">
							月経カレンダー機能を中心に、予約機能、体外受精における採卵や受精情報、胚観察記録など胚培養士が中心に利用する胚一覧管理機能、
							卵子および精子、胚の凍結保存に伴うタンク管理機能などに展開する構造を持っているシステムです。<br>
							<img class="img2" src="./assets/warabee2.png" />
						</div>
					</div>
				</div>
				<a name="function" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div>
					<span class="sub_title section_bar" style="padding:6px">主な機能</span>
					<div>
						<table class="funcList" style="width:100%">
							<tr>
								<td class="title"><div>月経カレンダー</div></td>
								<td class="discription">カルテ入力、処方・注射オーダ、月経スケジュール、治療基本方針、ARI実施記録</td>
							</tr>
							<tr>
								<td class="title"><div>予約</div></td>
								<td class="discription">採卵予約、人工授精予約、胚融解予約、凍結融解胚移植予約</td>
							</tr>
							<tr>
								<td class="title"><div>胚管理</div></td>
								<td class="discription">採卵、媒精、受精情報、胚観察記録</td>
							</tr>
							<tr>
								<td class="title"><div>タンク管理</div></td>
								<td class="discription">卵子凍結保存、精子凍結保存、胚凍結保存、保存期限切れ予告</td>
							</tr>
						</table>
					</div>
				</div>
				<a name="request" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div class="request_area">
					<div class="titlesize">資料請求</div>
					<div style="margin-top:32px">不妊治療支援システム Warabee に関する資料のお求めや、ご質問等につきましては、以下よりお気軽にお問い合わせ下さい。</div>
					<div class="titlesize button_style" style="margin-top:32px"><router-link to="/contact?flg=1">資料請求はこちら</router-link></div>
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import bizMenu from "./cp_businessMenu.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'busiWarabeePage',
	components: {
		"header-bar": headerBar,
		"biz-menu": bizMenu,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
.busi-warabee {
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	
	.navi_area {
		width: 950px;
		margin: 22px auto 16px;
	}

	.bgImage_area {
		height: 160px;
		background-image: url(./assets/warabee_top2.jpg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.conetnts_area {
		width: 950px;
		margin: 22px auto;
		
		.titlesize {
			font-size: @font-titlesize;
		}
		
		.description {
			text-align: center;
			font-size: @font-lagesize;
			line-height: 23px;
			margin: 32px 0;
		}
		
		.wbpanel1 {
			position: relative;
			height: 380px;
			margin: 32px 0 64px;
			background-image: url(./assets/warabee2.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			
			.disc1 {
				position: absolute;
				width: 560px;
				height: 240px;
				right: 32px;
				bottom: 0;
				padding: 12px;
				border-radius: 18px;
				background-color: rgba(0, 0, 0, 0.3);
				color: @white-textcolor;
				box-shadow: 2px 2px 10px 2px @shadow-color;
				line-height: 23px;
				font-size: @font-defailtsize;
			}
		}
		
		.wbpanel2 {
			position: relative;
			height: 380px;
			margin: 32px 0 184px;
			background-image: url(./assets/warabee3.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			
			.disc1 {
				position: absolute;
				width: 560px;
				height: 240px;
				left: 32px;
				bottom: 0;
				padding: 12px;
				border-radius: 18px;
				background-color: rgba(0, 0, 0, 0.3);
				color: @white-textcolor;
				box-shadow: 2px 2px 10px 2px @shadow-color;
				line-height: 23px;
				font-size: @font-defailtsize;
				
				img {
					margin-left:60px
				}
			}
		}
		
		.img1 {
			margin-left: 60px;
		}
		
		.funcList {
			margin-top: 24px;
			font-size: @font-lagesize;
			
			tr {
				&:first-child {
					.title, .discription {
						border-top: 1px solid @border-def;
					}
				}
			}
			
			.title {
				width: 180px;
				text-align: center;
				padding: 12px 0;
				border-bottom: 1px solid @border-def;
				
				div {
					padding: 8px;
					background-color: #B8D999;
				}
			}
			
			.discription {
				padding: 12px;
				border-bottom: 1px solid @border-def;
			}
		}
		
		.request_area {
			margin: 64px;
			text-align: center;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.busi-warabee {
		.navi_area {
			width: 650px;
		}
		
		.bgImage_area {
			height: 103px;
		}
		
		.conetnts_area {
			width: 650px;
			
			.wbpanel2 {
				.disc1 {
					img {
						margin-left:30px
					}
				}
			}
			
			.funcList {
				.title {
					width: 138px;
				}
				
				.discription {
					line-height: 21px;
					height: 43px;
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.busi-warabee {
		.navi_area {
			width: 300px;
			margin-top: 90px;
		}
		
		.bgImage_area {
			height: 103px;
		}
		
		.conetnts_area {
			width: 300px;
			
			.wbpanel1 {
				height: 120px;
				margin: 32px 0 170px;
				
				.disc1 {
					font-size: @font-sssize;
					right: 8px;
					width: 240px;
					height: 100px;
					bottom: -50px;
				}
			}
			
			.wbpanel2 {
				height: 120px;
				margin: 32px 0 170px;
				
				.disc1 {
					font-size: @font-sssize;
					left: 8px;
					width: 240px;
					height: 120px;
					bottom: -80px;
				}
			}
			
			.img1 {
				width: 240px;
				height: 162px;
				margin-left: 4px;
			}
			
			.img2 {
				width: 240px;
				height: 149.5px;
			}
			
			.funcList {
				font-size: @font-sssize;
				
				.title {
					width: 100px;
				}
				
				.discription {
					line-height: 14px;
					height: 38px;
				}
			}
			
			.request_area {
				margin: 64px 0;
				text-align: center;
			}
		}
	}
}
</style>
