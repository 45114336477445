import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './pg_home.vue';
import Company from './pg_company.vue';
import Business from './pg_business.vue';
import Recruit from './pg_recruit.vue';
import Contact from './pg_contact.vue';
import ContactRev from './pg_contact_rev.vue';
import CompPhilosophy from './pg_company_philosophy.vue';
import CompHistory from './pg_company_history.vue';
import CompGuide from './pg_company_guide.vue';
import BusiCRESC from './pg_business_cresc.vue';
import BusiLABO from './pg_business_medlabo.vue';
import BusiWarabee from './pg_business_warabee.vue';
import BusiMedcube from './pg_business_medcube.vue';
import BusiCase from './pg_business_casestudy.vue';
import BusiDxSolution from './pg_business_dxsolution.vue';
import BusiTanom from './pg_business_tanom.vue';
import Privacy from './pg_privacy.vue';
import Information from './pg_information.vue';
import Product from './pg_product.vue';
import HumanRights from './pg_humanrights.vue';

Vue.use(VueRouter);

const routes = [
	{ path: '/', component: Home },
	{ path: '/company', component: Company },
	{ path: '/company/philosophy', component: CompPhilosophy },
	{ path: '/company/history', component: CompHistory },
	{ path: '/company/guide', component: CompGuide },
	{ path: '/business', component: Business },
	{ path: '/business/cresc', component: BusiCRESC },
	{ path: '/business/medlabo', component: BusiLABO },
	{ path: '/business/warabee', component: BusiWarabee },
	{ path: '/business/medcube', component: BusiMedcube },
	{ path: '/business/casestudy', component: BusiCase },
	{ path: '/business/dxsolution', component: BusiDxSolution },
	{ path: '/business/tanom', component: BusiTanom },
	{ path: '/product', component: Product },
	{ path: '/recruit', component: Recruit },
	{ path: '/contact', component: Contact },
	{ path: '/contact/rev', component: ContactRev },
	{ path: '/privacy', component: Privacy },
	{ path: '/humanrights', component: HumanRights },
	{ path: '/information/:pn', name: 'information', component: Information },
	{ path: '*', redirect: '/' }
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior (to, from, savedPosition) {
		const selector = location.hash
		return selector ? { selector } : { x: 0, y: 0 }
	}
});

export default router;
