<template>
    <transition name="sloganArea">
        <div class="slogan" v-show="position > -1">
            <router-link to="/company/philosophy#slogan" class="slogan_text">～DATA CREATES THE FUTURE～<br><br>データの力で未来を切り開く</router-link>
        </div>
    </transition>
</template>

<script>
window.addEventListener('load', function() {
	
});

export default {
	data: function(){
		return {
			position: -1
		}
	},
	methods: {
		handleResize: function(e){
			var slogan = document.getElementsByClassName("slogan")[0];
			var top = ((window.innerHeight - slogan.clientHeight) / 2) - 150;
			//console.log('innerHeight=' + window.innerHeight + ' : clientHeight=' + slogan.clientHeight + ' : top=' + top);
			if(top < 0) top = 0;
			slogan.style.top = top + "px";
			//slogan.style.top = (((window.innerHeight - slogan.clientHeight) / 2) + 50) + "px";
		}
	},
	created: function(){
		window.addEventListener('resize', this.handleResize);
	},
	mounted: function(){
		var _self = this;

        this.handleResize();
		
		setTimeout(function () {
			_self.position = 0;
		}, 100);

	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
.slogan {
    position: relative;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    color: #555;
    text-shadow:2px 2px 6px rgba(255, 255, 255, 0.6), -2px -2px 6px rgba(255, 255, 255, 0.6), 2px -2px 6px rgba(255, 255, 255, 0.6), -2px 2px 6px rgba(255, 255, 255, 0.6);
    .slogan_text {
        font-size: 250%;
        font-weight: 700;
    }
}

.sloganArea-enter-active, .sloganArea-leave-active {
	transition: all 2.5s ease;
	-webkit-transition: all 2.5s ease;
}
.sloganArea-enter, .sloganArea-leave-to {
	top: -150;
	opacity: 0;
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.slogan {
		max-width: 650px;
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.slogan {
		max-width: 300px;
		
		.menus {
			float: none;
			font-size: 12px;
		}
	}
}
</style>
