// 導入事例
<template>
	<div id="casestudy-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="height:200px; margin-top:76px"></div>
		<div class="content_area">
			<div class="sub_title section_bar" style="padding:6px">導入先一例</div>
			<div class="smallsize" style="margin-top:32px; text-align:right">※五十音順</div>
			<div class="item_area">
				<div class="itemPanel" v-for="(item, index) in items" :item="item" :key="item.id" v-bind:class="{ 'item1':isLastItem(index) }">
					<div class="itemImage" v-bind:style="{ backgroundImage: `url('${imgurl(item.image)}')` }"></div>
					<div class="namePanel"><div v-if="(item.subname != '')&&(item.subname != undefined)" class="smallsize">{{ item.subname }}</div><a :href="item.url" target="_brank">{{ item.name }} <icon name="external-link" scale="0.8"></icon></a></div>
					<div class="productPanel">
						<div v-for="(prod, index) in item.product">{{ prod }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import axios from 'axios';
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'casestudyPage',
	components: {
		"header-bar": headerBar,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	data: function(){
		return {
			"items":[]
		}
	},
	methods: {
		scrollTo: function(name) {
			
		},
		getItems: function() {
			let res = axios.get("/casestudy.json", {
				timeout: 3000,
				responseType: 'json'
			}).then(res => {
				this.items = [];
				for (var i=0; i<res.data.length; i++) {
					if (res.data[i].disable != true) {
						this.items.push(res.data[i]);
					}
				}
			}).catch(e => {
				console.log(e);
			});
		},
		imgurl: function(filename) {
			const url = require(`./assets/${filename}.jpg`);
			return url;
		},
		isLastItem(index) {
			var res = false;
			if ((this.items.length % 3) != 0) {
				if (index == (this.items.length - 1)) {
					res = true;
				}
			}
			return res;
		}
	},
	created: function(){
		this.getItems();
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#casestudy-page {
	.main_area {
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/ground1.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}
	
	.content_area {
		width: 950px;
		margin: 24px auto;
		line-height: 20.9px;
		
		.smallsize {
			font-size: @font-sssize;
		}
		
		.item_area {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			
			.itemPanel {
				display: inline-block;
				width: 268px;
				height: 400px;
				border: 1px solid @border-def;
				border-radius: 10px;
				margin: 40px 0;
				box-shadow: 2px 2px 6px 2px @shadow-color;
				
				.itemImage {
					border-top-right-radius: 10px;
					border-top-left-radius: 10px;
					background-color: @gbcolor-title;
					height: 160px;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
					border-bottom: 1px solid @gbcolor-title;
				}
				
				.namePanel {
					padding: 24px 8px 24px;
					height: 87px;
				}
				
				.productPanel {
					width: calc(100% - 6px);
					padding: 12px 8px;
					border-top: 1px dashed @border-def;
				}
			}
			
			.item1 {
				margin-right: 340px;
			}
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#casestudy-page {
		.content_area {
			width: 650px;
			
			.item_area {
				.item1 {
					margin-right: 0;
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#casestudy-page {
		.content_area {
			width: 300px;
			
			.item_area {
				.item1 {
					margin-right: 0;
				}
			}
		}
	}
}
</style>
