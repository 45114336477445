// インフォメーション表示
<template>
	<div class="info-content">
		<vue-markdown :source="source"></vue-markdown>
	</div>
</template>

<script>
import axios from 'axios';
import VueMarkdown from 'vue-markdown';
export default {
	components: {
		'vue-markdown': VueMarkdown
	},
	props:[
		"filename"
	],
	data () {
		return {
			source: ""
		}
	},
	methods: {
		loadPage: function(filename) {
			let res = axios.get("/note/" + filename + ".txt", {
				timeout: 3000
			}).then(res => {
				this.source = res.data;
			}).catch(e => {
				console.log(e);
			});
		}
	},
	mounted: function(){
		this.loadPage(this.filename);
	}
}
</script>

<style lang="less">
.info-content {
	min-height: 480px;
}
</style>
