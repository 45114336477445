// med@LABOの紹介ページ
<template>
	<div class="busi-labo">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<div class="navi_area"><span class="navi_bar_link"><router-link to="/">TOP</router-link> &gt; <router-link to="/product">PRODUCT</router-link> &gt;</span> med@LABO</div>
			<div class="bgImage_area"></div>
			<div class="conetnts_area">
				<biz-menu></biz-menu>
				<div class="description">
					ｍed@LABO（ラボ）は、<span class="decoration">検査科の業務効率向上</span>はもとより<br>
					病院内の職員・患者様においても<span class="decoration">有意義な機能を満載</span>したシステムです。
				</div>
				<div class="tbl image_area" style="width:100%">
					<div class="tblrow">
						<div class="tblcell" style="width:50%">
							<img class="contentImg1" src="./assets/medlabo_panel1.png" />
						</div>
						<div class="tblcell" style="width:50%; vertical-align:middle">
							<img class="contentImg2" src="./assets/medlabo_panel2.png" />
							<div class="titlesize disc1">検査システム med@LABO は、シンプルで見読性に優れた画面と充実した機能をご提供致します。</div>
						</div>
					</div>
				</div>
				<a name="outline" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div v-if="!isSmartPhone" class="tbl" style="width:100%; margin-top: 32px;">
					<div class="tblrow" style="height:140px">
						<div class="tblcell panel panel1">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube1.png"/><div class="title">柔軟性</div></td>
									<td class="disc">小規模から大規模までの病院に対応し、後に、健診や地域連携など将来の発展にも柔軟に対応</td>
								</tr>
							</table>
						</div>
						<div class="tblcell" style="width:32px"></div>
						<div class="tblcell panel panel2">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube2.png"/><div class="title">リアル<br>タイム</div></td>
									<td class="disc">オーダ状況、結果入力状況、異常データ内容、再検査状況等をリアルタイムで確認</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="tblrow">
						<div class="tblcell"></div>
						<div class="tblcell" style="width:32px; height:22px"></div>
						<div class="tblcell"></div>
					</div>
					<div class="tblrow" style="height:140px">
						<div class="tblcell panel panel3">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube3.png"/><div class="title">安全性</div></td>
									<td class="disc">ペーパーレス運用やバーコードの利用等により、システムを安全に、且つ、効率よく利用する事が可能</td>
								</tr>
							</table>
						</div>
						<div class="tblcell" style="width:32px"></div>
						<div class="tblcell panel panel4">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube4.png"/><div class="title">システム<br>連携</div></td>
									<td class="disc">医事システム、電子カルテ、オーダリングシステム及び、その他外部システムとの連携も柔軟に対応</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="tblrow">
						<div class="tblcell"></div>
						<div class="tblcell" style="width:32px; height:22px"></div>
						<div class="tblcell"></div>
					</div>
					<div class="tblrow" style="height:140px">
						<div class="tblcell panel panel5">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube5.png"/><div class="title">操作性</div></td>
									<td class="disc">分かり易く簡素化された画面構成により直感的な操作が可能</td>
								</tr>
							</table>
						</div>
						<div class="tblcell" style="width:32px"></div>
						<div class="tblcell panel panel6">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube6.png"/><div class="title">装置連携</div></td>
									<td class="disc">分析メーカーを問わず接続に対応。また分析装置以外にも、オートラベラーや IoT機器との接続も対応可能</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div v-if="isSmartPhone" class="tbl" style="width:100%; margin-top: 32px;">
					<div class="tblrow" style="height:140px">
						<div class="tblcell panel panel1">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube1.png"/><div class="title">柔軟性</div></td>
									<td class="disc">小規模から大規模までの病院に対応し、後に、健診や地域連携など将来の発展にも柔軟に対応</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="tblrow" style="height:12px">
						<div class="tblcell"></div>
					</div>
					<div class="tblrow" style="height:140px">
						<div class="tblcell panel panel2">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube2.png"/><div class="title">リアル<br>タイム</div></td>
									<td class="disc">オーダ状況、結果入力状況、異常データ内容、再検査状況等をリアルタイムで確認</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="tblrow" style="height:12px">
						<div class="tblcell"></div>
					</div>
					<div class="tblrow" style="height:140px">
						<div class="tblcell panel panel3">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube3.png"/><div class="title">安全性</div></td>
									<td class="disc">ペーパーレス運用やバーコードの利用等により、システムを安全に、且つ、効率よく利用する事が可能</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="tblrow" style="height:12px">
						<div class="tblcell"></div>
					</div>
					<div class="tblrow" style="height:140px">
						<div class="tblcell panel panel4">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube4.png"/><div class="title">システム<br>連携</div></td>
									<td class="disc">医事システム、電子カルテ、オーダリングシステム及び、その他外部システムとの連携も柔軟に対応</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="tblrow" style="height:12px">
						<div class="tblcell"></div>
					</div>
					<div class="tblrow" style="height:140px">
						<div class="tblcell panel panel5">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube5.png"/><div class="title">操作性</div></td>
									<td class="disc">分かり易く簡素化された画面構成により直感的な操作が可能</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="tblrow" style="height:12px">
						<div class="tblcell"></div>
					</div>
					<div class="tblrow" style="height:140px">
						<div class="tblcell panel panel6">
							<table style="width:100%; height:140px">
								<tr>
									<td class="mark"><img class="cube" src="./assets/cube6.png"/><div class="title">装置連携</div></td>
									<td class="disc">分析メーカーを問わず接続に対応。また分析装置以外にも、オートラベラーや IoT機器との接続も対応可能</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<a name="function" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div class="sub_title">■主な機能</div>
				<div>
					<table class="funcList" style="width:100%">
						<tr>
							<td class="title"><div>各種依頼</div></td>
							<td class="discription">予約依頼、随時依頼、通常依頼、緊急依頼、一括依頼、依頼項目の追加・変更</td>
						</tr>
						<tr>
							<td class="title"><div>実施</div></td>
							<td class="discription">実施順番は自由に変更可能。また、バーコード利用による操作性を向上</td>
						</tr>
						<tr>
							<td class="title"><div>機器連携</div></td>
							<td class="discription">自動分注機、採血管前準備装置、自動搬送装置との連携</td>
						</tr>
						<tr>
							<td class="title"><div>依頼取り込み</div></td>
							<td class="discription">医事システムからの患者情報取得、電子カルテ・オーダリングシステムからの依頼情報取込</td>
						</tr>
						<tr>
							<td class="title"><div>結果転送</div></td>
							<td class="discription">医事システムへの実施情報転送、電子カルテ・オーダリングシステムへの結果情報転送</td>
						</tr>
					</table>
				</div>
				<a name="request" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div class="request_area">
					<div class="titlesize">資料請求</div>
					<div style="margin-top:32px">検査システム med@LABO に関する資料のお求めや、ご質問等につきましては、以下よりお気軽にお問い合わせ下さい。</div>
					<div class="titlesize button_style" style="margin-top:32px"><router-link to="/contact?flg=1">資料請求はこちら</router-link></div>
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import bizMenu from "./cp_businessMenu.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'busiLaboPage',
	components: {
		"header-bar": headerBar,
		"biz-menu": bizMenu,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	data: function(){
		return {
			isSmartPhone: false
		}
	},
	methods: {
		handleResize: function(e) {
			if (window.matchMedia('(max-width:480px)').matches) {
				// SPの処理
				this.isSmartPhone = true;
			} else if (window.matchMedia('(max-width:800px)').matches) {
				// TABの処理
				this.isSmartPhone = false;
			} else {
				// PCの処理
				this.isSmartPhone = false;
			}
		}
	},
	created: function(){
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
.busi-labo {
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	
	.navi_area {
		width: 950px;
		margin: 22px auto 16px;
	}
	
	.bgImage_area {
		height: 160px;
		background-image: url(./assets/medlabo_top2.jpg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	.conetnts_area {
		width: 950px;
		margin: 22px auto;
		
		.description {
			text-align: center;
			font-size: @font-titlesize;
			line-height: 23px;
			margin: 32px 0;
		}
		
		.disc1 {
			font-size: @font-titlesize;
			line-height: 23px;
		}
		
		.image_area {
			background-image: url("./assets/cross.png");
			
			.contentImg1 {
			}
			
			.contentImg2 {
			}
		}
		
		.decoration {
			font-size: @font-bigsize;
			font-weight: bold;
			color: #AD3E41;
			text-stroke: 1px #FFF;
			text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
		}
		
		.panel {
			height: 140px;
			border-radius: 4px;
			box-shadow: 2px 2px 4px 2px @shadow-color;
			vertical-align: top;
			
			.mark {
				position: relative;
				text-align: center;
				width: 120px;
				font-weight: bold;
				font-size: @font-titlesize;
				margin: 0;
				padding: 0;
				
				.cube {
					position: absolute;
					top: 20px;
					left: 16px;
				}
				
				.title {
					position: relative;
					top: 1px;
					color: @white-textcolor;
					text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.4),
							-1px 1px 0 rgba(0, 0, 0, 0.4),
							1px -1px 0 rgba(0, 0, 0, 0.4),
							-1px -1px 0 rgba(0, 0, 0, 0.4),
							2px 2px 4px rgba(0, 0, 0, 0.4);
				}
			}
			
			.disc {
				width: 319px;
				padding-right: 10px;
				text-align: left;
				vertical-align: middle;
				font-weight: bold;
				font-size: @font-lagesize;
				line-height: 20.9px;
			}
		}
		
		.panel1 {
			background: #eaf1ff; /* Old browsers */
			background: -moz-linear-gradient(top, #eaf1ff 0%, #c4d9ff 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #eaf1ff 0%,#c4d9ff 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #eaf1ff 0%,#c4d9ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaf1ff', endColorstr='#c4d9ff',GradientType=0 ); /* IE6-9 */
		}
		
		.panel2 {
			background: #eafff0; /* Old browsers */
			background: -moz-linear-gradient(top, #eafff0 0%, #c4ffd3 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #eafff0 0%,#c4ffd3 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #eafff0 0%,#c4ffd3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eafff0', endColorstr='#c4ffd3',GradientType=0 ); /* IE6-9 */
		}
		
		.panel3 {
			background: #f4eaff; /* Old browsers */
			background: -moz-linear-gradient(top, #f4eaff 0%, #dfc4ff 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #f4eaff 0%,#dfc4ff 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #f4eaff 0%,#dfc4ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4eaff', endColorstr='#dfc4ff',GradientType=0 ); /* IE6-9 */
		}
		
		.panel4 {
			background: #fff8ea; /* Old browsers */
			background: -moz-linear-gradient(top, #fff8ea 0%, #ffedc4 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #fff8ea 0%,#ffedc4 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #fff8ea 0%,#ffedc4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff8ea', endColorstr='#ffedc4',GradientType=0 ); /* IE6-9 */
		}
		
		.panel5 {
			background: #feffea; /* Old browsers */
			background: -moz-linear-gradient(top, #feffea 0%, #fdffc4 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #feffea 0%,#fdffc4 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #feffea 0%,#fdffc4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feffea', endColorstr='#fdffc4',GradientType=0 ); /* IE6-9 */
		}
		
		.panel6 {
			background: #ffedea; /* Old browsers */
			background: -moz-linear-gradient(top, #ffedea 0%, #ffcdc4 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #ffedea 0%,#ffcdc4 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #ffedea 0%,#ffcdc4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffedea', endColorstr='#ffcdc4',GradientType=0 ); /* IE6-9 */
		}
		
		.sub_title {
			font-weight: bold;
			font-size: @font-lagesize;
			margin-top: 64px;
		}
		
		.titlesize {
			font-size: @font-titlesize;
		}
		
		.funcList {
			margin-top: 24px;
			font-size: @font-lagesize;
			
			tr {
				&:first-child {
					.title, .discription {
						border-top: 1px solid @border-def;
					}
				}
			}
			
			.title {
				width: 180px;
				text-align: center;
				padding: 12px 0;
				border-bottom: 1px solid @border-def;
				
				div {
					padding: 8px;
					background-color: #B8D999;
				}
			}
			
			.discription {
				padding: 12px;
				border-bottom: 1px solid @border-def;
			}
		}
		
		.request_area {
			margin: 64px;
			text-align: center;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.busi-labo {
		.navi_area {
			width: 650px;
		}
		
		.bgImage_area {
			height: 103px;
		}
		
		.conetnts_area {
			width: 650px;
			
			.image_area {
				.contentImg1 {
					width: 280px;
					height: 263.3px;
				}
				
				.contentImg2 {
					width: 260px;
					height: 236.7px;
				}
			}
			
			.panel {
				.mark {
					.cube {
						width: 78px;
						height: 83px;
						top: 28px;
						left: 3px;
					}
				}
			}
			
			.funcList {
				.title {
					width: 138px;
				}
				
				.discription {
					line-height: 21px;
					height: 43px;
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.busi-labo {
		.navi_area {
			width: 300px;
			margin-top: 90px;
		}
		
		.bgImage_area {
			height: 103px;
		}
		
		.conetnts_area {
			width: 300px;
			
			.disc1 {
				font-size: @font-sssize;
				line-height: 14px;
			}
			
			.image_area {
				.contentImg1 {
					width: 140px;
					height: 131.7px;
				}
				
				.contentImg2 {
					width: 140px;
					height: 127.5px;
				}
			}
			
			.funcList {
				font-size: @font-sssize;
				
				.title {
					width: 100px;
				}
				
				.discription {
					line-height: 14px;
					height: 38px;
				}
			}
			
			.request_area {
				margin: 64px 0;
				text-align: center;
			}
		}
	}
}
</style>
