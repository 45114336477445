<template>
	<div class="sitemap">
		<div class="tbl sitemap_tbl" style="width:100%">
			<div class="tblrow">
				<div class="tblcell borderline"></div>
				<div class="tblcell menus">
					<router-link to="/company">会社概要</router-link>
					<ul class="list">
						<li><router-link to="/company/philosophy">経営理念</router-link></li>
						<li><router-link to="/company/history">会社沿革</router-link></li>
						<li>
							<router-link to="/company/guide">事業所</router-link>
							<ul class="list">
								<li><router-link to="/company/guide/#fukuoka">本社</router-link></li>
								<li><router-link to="/company/guide/#miyazaki">宮崎営業所</router-link></li>
								<li><router-link to="/company/guide/#kagoshima">鹿児島営業所</router-link></li>
								<li><router-link to="/company/guide/#okinawa">沖縄営業所</router-link></li>
								<li><router-link to="/company/guide/#chiba">千葉開発室</router-link></li>
							</ul>
						</li>
					</ul>
				</div>
				<div class="tblcell borderline"></div>
				<div class="tblcell menus">
					<router-link to="/business">事業内容</router-link>
					<ul class="list">
						<li>
							<router-link to="/product">製品紹介</router-link>
							<ul class="list">
								<li>
									<router-link to="/business/cresc">CRESCソリューション</router-link>
									<ul class="list">
										<li><router-link to="/business/cresc">病院情報システム CRESC</router-link></li>
										<li><router-link to="/business/medlabo">検査システム med@LABO</router-link></li>
										<li><router-link to="/business/warabee">不妊治療システム Warabee</router-link></li>
									</ul>
								</li>
								<li><router-link to="/business/medcube">診療支援データウェアハウス MedicalCube</router-link></li>
								<li><router-link to="/business/tanom">簡易受発注システム TANOM</router-link></li>
							</ul>
						</li>
						<li v-if="($store.state.casestudy)">
							<router-link to="/business/casestudy">導入事例</router-link>
						</li>
						<li><router-link to="/business/dxsolution">DXソリューション</router-link></li>
					</ul>
				</div>
				<div class="tblcell borderline"></div>
				<div class="tblcell menus">
					<router-link to="/recruit">採用情報</router-link>
				</div>
				<div class="tblcell borderline"></div>
				<div class="tblcell menus">
					<router-link to="/contact">お問い合わせ</router-link>
					<div style="margin-top:8px"><router-link to="/privacy">プライバシーポリシー</router-link></div>
					<div style="margin-top:8px"><router-link to="/humanrights">人権基本方針</router-link></div>
				</div>
			</div>
		</div>
		<div class="tbl sitemap_tbl_mb" style="width:100%">
			<div class="tblrow borderline2">
				<div class="tblcell">
				</div>
			</div>
			<div class="tblrow">
				<div class="tblcell menus">
					<router-link to="/company">会社概要</router-link>
					<ul class="list">
						<li><router-link to="/company/history">会社沿革</router-link></li>
						<li>
							<router-link to="/company/guide">事業所</router-link>
							<ul class="list">
								<li><router-link to="/company/guide/#fukuoka">本社</router-link></li>
								<li><router-link to="/company/guide/#miyazaki">宮崎営業所</router-link></li>
								<li><router-link to="/company/guide/#kagoshima">鹿児島営業所</router-link></li>
								<li><router-link to="/company/guide/#okinawa">沖縄営業所</router-link></li>
								<li><router-link to="/company/guide/#chiba">千葉開発室</router-link></li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<div class="tblrow" style="height:22px">
				<div class="tblcell">
				</div>
			</div>
			<div class="tblrow borderline2">
				<div class="tblcell">
				</div>
			</div>
			<div class="tblrow">
				<div class="tblcell menus">
					<router-link to="/business">事業内容</router-link>
					<ul class="list">
						<li>
							<router-link to="/product">製品紹介</router-link>
							<ul class="list">
								<li>
									<router-link to="/business/cresc">CRESCソリューション</router-link>
									<ul class="list">
										<li><router-link to="/business/cresc">病院情報システム CRESC</router-link></li>
										<li><router-link to="/business/medlabo">検査システム med@LABO</router-link></li>
										<li><router-link to="/business/warabee">不妊治療システム Warabee</router-link></li>
									</ul>
								</li>
								<li><router-link to="/business/medcube">診療支援データウェアハウス MedicalCube</router-link></li>
								<li><router-link to="/business/tanom">簡易受発注システム TANOM</router-link></li>
							</ul>
						</li>
						<li v-if="($store.state.casestudy)">
							<router-link to="/business/casestudy">導入事例</router-link>
						</li>
						<li><router-link to="/business/dxsolution">DXソリューション</router-link></li>
					</ul>
				</div>
			</div>
			<div class="tblrow" style="height:22px">
				<div class="tblcell">
				</div>
			</div>
			<div class="tblrow borderline2">
				<div class="tblcell">
				</div>
			</div>
			<div class="tblrow">
				<div class="tblcell menus">
					<router-link to="/recruit">採用情報</router-link>
				</div>
			</div>
			<div class="tblrow" style="height:22px">
				<div class="tblcell">
				</div>
			</div>
			<div class="tblrow borderline2">
				<div class="tblcell">
				</div>
			</div>
			<div class="tblrow">
				<div class="tblcell menus">
					<router-link to="/contact">お問い合わせ</router-link>
					<div style="margin-top:8px"><router-link to="/privacy">プライバシーポリシー</router-link></div>
					<div style="margin-top:8px"><router-link to="/humanrights">人権基本方針</router-link></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: function(){
		return {
			isSmartPhone: false
		}
	},
	methods: {
		handleResize: function(e) {
			if (window.matchMedia('(max-width:480px)').matches) {
				// SPの処理
				this.isSmartPhone = true;
			} else if (window.matchMedia('(max-width:800px)').matches) {
				// TABの処理
				this.isSmartPhone = false;
			} else {
				// PCの処理
				this.isSmartPhone = false;
			}
		}
	},
	created: function() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('load', (e) => {
			this.handleResize(e);
		});
	}
}
</script>

<style lang="less" scoped>
.sitemap {
	width: 950px;
	padding: 22px 0 0;
	margin-left: auto;
	margin-right: auto;
	
	ul, ol {
		list-style: none;
	}
	
	ul {
		margin: 0 0 0 20px;
		padding: 0;
		vertical-align: baseline;
	}
	
	li {
		margin: 8px 0 0;
		padding: 0;
		vertical-align: baseline;
	}
	
	.sitemap_tbl_mb {
		display: none;
	}
	
	.menus {
		padding: 0 4px 0;
	}

	.borderline {
		width: 1px; /* FF3.6-15 */
		background: -webkit-linear-gradient(rgba(198,198,198,1) 0%, rgba(198,198,198,0) 100%);
		background: -o-linear-gradient(rgba(198,198,198,1) 0%, rgba(198,198,198,0) 100%);
		background: linear-gradient(rgba(198,198,198,1) 0%, rgba(198,198,198,0) 100%); /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c6c6c6', endColorstr='#00c6c6c6',GradientType=0 ); /* IE6-9 */
	}
}
/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.sitemap {
		width: 650px;
		font-size: 11.6px;
		
		ul {
			margin: 0 0 0 12px;
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.sitemap {
		width: 300px;
		font-size: 11.6px;
		
		ul {
			margin: 0 0 0 12px;
		}
		
		.sitemap_tbl {
			display: none;
		}
		
		.sitemap_tbl_mb {
			display: table;
			
		}
		
		.borderline2 {
			height: 1px;
			background: -moz-linear-gradient(left, rgba(198,198,198,1) 0%, rgba(198,198,198,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(198,198,198,1) 0%,rgba(198,198,198,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(198,198,198,1) 0%,rgba(198,198,198,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c6c6c6', endColorstr='#00c6c6c6',GradientType=1 ); /* IE6-9 */
		}
	}
}
</style>
