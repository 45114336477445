import Vue from 'vue';
import store from './vuex/store'
import router from './router.js';
import App from './App.vue';
import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon';

Vue.component('icon', Icon)

var app = new Vue({
	el: '#app',
	store,
	router,
	render: h => h(App)
})
