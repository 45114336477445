<template>
	<div id="privacy-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px"></div>
		<div class="content_area">
			<div class="comment_panel">
				<div class="sub_title underbar" style="font-weight:bold">個人情報保護の考え方</div>
				<div style="text-align:right; margin:6px 0">
					令和3年9月1日最終改訂<br>
					データキューブ株式会社<br>
					代表取締役　上村　慎一郎
				</div>
				<div style="font-weight:bold; margin:12px 0">
					　データキューブ株式会社（以下「当社」という）は、個人情報の重要性を常に認識したうえで、
					顧客の信頼にお応えするため、当社で取り扱う全ての個人情報の保護に努めます。
					当社の業務におけるすべての個人情報をより厳正に取り扱うため、個人情報保護方針を以下の通り定め、
					これを実行し維持することといたします。
				</div>
				<div style="margin:12px 0">
					1. 当社は、個人情報を取得する場合には、事前に利用目的を明確にし、本人の同意を得た上で、
					利用目的の範囲内において適切に利用 します。本人の事前の同意を得ることなく、利用目的を超えて
					個人情報を利用（目的外利用）することはありません。
					また、目的外利用を行わないために必要となる措置を講じます。
				</div>
				<div style="margin:12px 0">
					2. 当社は、個人情報の取扱いに関する法令、国が定める指針及びその他の規範を遵守します。
				</div>
				<div style="margin:12px 0">
					3. 当社は、個人情報の漏洩、滅失又はき損に対して、予防措置を講ずると共に、万が一、問題が発生した場合
					には、速やかな是正対策を実施します。
				</div>
				<div style="margin:12px 0">
					4. 当社は、苦情及び相談に対して遅滞なく対応いたします。
				</div>
				<div style="margin:12px 0">
					5. 当社は、個人情報保護マネジメントシステムを継続的に見直し、改善を行います。
				</div>
				<div style="margin:24px 0">
					＜問い合わせ先＞<br>
					苦情・相談窓口：　個人情報保護管理者：宮原<br>
					TEL：092-433-3366
				</div>
			</div>
			<div style="margin:24px 0">
				<div class="sub_title underbar" style="font-weight:bold">個人情報のお取り扱いについて</div>
				<div class="sub_title2">1．個人情報の利用目的</div>
				<div style="margin-bottom:12px">
					当社は、次の各項の個人情報を利用目的の範囲内で取得し利用するものとします。
					開示対象の個人情報は以下の通りです。
				</div>
				<div style="margin-bottom:12px">
					<span class="bold_title"># 顧客及び仕入先の個人情報の利用目的は、</span><br>
					① 請求業務の為② 仕入及び支払いの為③ 業務に関する連絡の為
				</div>
				<div style="margin-bottom:12px">
					<span class="bold_title"># 採用希望者の個人情報の利用目的は、</span><br>
					① 採用の判断を行う選考の資料として② 応募者の皆様へ採用に関する情報提供として
				</div>
				<div style="margin-bottom:12px">
					<span class="bold_title"># 当社社員の個人情報の利用目的は、</span><br>
					① 採用となった方への入社手続き、入社後の人事、総務情報の連絡の為<br>
					② 労務管理の為(勤怠管理・通勤車両管理・給与計算・給与振込・年末調整等)<br>
					③ 各種保険(社会保険・介護保険・雇用保険等)の手続きの為<br>
					④ 法的機関(厚生労働省関係機関等)への届出の為<br>
					⑤ 各種福利厚生(健康診断・希望する各種団体への加入等)の手続きの為直接書面以外で取得する個人情報の
					利用目的は以下の通りです。<br>
					<div style="padding: 8px 0 0 48px">
						◯システム開発に用いるテストデータの使用の為以上を主目的としております。また、当社の正当な
						事業範囲内で目的を明確に定め、それを遵守して行います。なお、定められた目的以外での取得、
						利用、提供は行いません。
					</div>
				</div>
				<div class="sub_title2">2．開示対象個人情報について</div>
				<div style="margin-bottom:12px">
					（1） 事業者名、個人情報保護管理者
					<div style="padding: 8px 0 0 48px">
						◯データキューブ株式会社<br>
						◯個人情報保護管理者
					</div>
				</div>
				<div style="margin-bottom:12px">
					（2） 開示対象個人情報の利用目的
					<div style="padding: 8px 0 0 48px">
						◯上記1．の通り。
					</div>
				</div>
				<div style="margin-bottom:12px">
					（3） 開示等の手続きについて
					<div style="padding: 8px 0 0 48px">
						◯当社が保有している開示対象個人情報について、利用目的の通知、開示、内容の訂正、追加または削除、
						利用および第三者提供の停止のお申し出に対しては、すみやかに対応いたします。
						開示等のご請求の具体的な手続き（申し出先、申請書式、本人確認等）につきましては、下記の
						「個人情報についてのお問い合わせ先」までお問い合わせください。
					</div>
				</div>
				<div style="margin-bottom:12px">
					（4） 個人情報の取扱いに関するご相談・苦情について
					<div style="padding: 8px 0 0 48px">
						◯当社の個人情報の取扱いに関するご相談や苦情等のお問い合わせについては、下記の窓口までご連絡
						いただきますよう、お願い申し上げます。
					</div>
				</div>
				<div class="sub_title2">3．個人情報の安全管理のために講じた措置について</div>
				<div style="margin-bottom:12px">
					当社は、個人情報を適切に取り扱うため、JIS Q 15001に準拠した個人情報保護方針等を策定し、個人情報保護マネジメントシステムを運用しております。<br />
					また、個人情報を取り扱う際は、組織的・人的・物理的・技術的観点に基づき安全管理措置を講じております。<br />
					安全管理措置の詳細については、下記【個人情報についてのお問い合わせ・相談窓口】にお問い合わせください。
				</div>
				<div style="margin:42px 0">
					＜個人情報についてのお問い合わせ先＞<br>
					データキューブ株式会社個人情報お問い合わせ窓口：個人情報保護管理者：宮原<br>
					TEL：092-433-3366
				</div>
			</div>
			<div class="p_mark"><a href="https://privacymark.jp/" target="_brank"><img style="width:8rem" src="./assets/pmark.png"/></a></div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'privacyPage',
	components: {
		"header-bar": headerBar,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#privacy-page {
	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/privacy.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}

	.content_area {
		width: 950px;
		margin: 24px auto;
		line-height: 20.9px;

		.comment_panel {
			border-radius: 8px;
			border: 1px solid @border-def;
			background-color: #FAFAFA;
			padding: 12px;
			box-shadow: 1px 1px 6px 1px @shadow-color;
		}

		.sub_title2 {
			.section_bar;
			margin: 12px 0;
			padding: 6px 0 6px 8px;
			font-weight: bold;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#privacy-page {
		.content_area {
			width: 650px;
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#privacy-page {
		.main_area {
			height: 140px;
		}

		.content_area {
			width: 300px;
		}
	}
}
</style>
