<template>
	<div class="businessSlideArea">
		<div class="slide">
			<div class="panel1" @click="linkPage()"></div><div class="panel2" @click="linkPage()"></div>
		</div>
		<div class="guide"><span class="active" @click="changeImage(0)">・</span><span @click="changeImage(1)">・</span><span @click="changeImage(2)">・</span><span @click="changeImage(3)">・</span></div>
	</div>
</template>

<script>
export default {
	data: function(){
		return {
			intervalid: '',
			bgImages: ['bgImage1', 'bgImage2', 'bgImage3', 'bgImage4'],
			links: ['/business/cresc', '/business/medlabo', '/business/warabee', '/business/medcube'],
			pos: 0
		}
	},
	methods: {
		startBgImage: function(){
			var _self = this;
			this.intervalid = setInterval(function(){
				++_self.pos;
				if (_self.pos >= _self.bgImages.length) {
					_self.pos = 0;
				}
				
				_self.setBgImage(_self.pos);
			}, 7000);
		},
		setBgImage: function(ipos){
			var base = document.getElementsByClassName("businessSlideArea")[0];
			var p1 = base.getElementsByClassName("panel1")[0];
			var p2 = base.getElementsByClassName("panel2")[0];
			var gp = base.getElementsByClassName("guide")[0];
			
			for (var i=0; i < gp.getElementsByTagName("span").length; i++) {
				var ep = gp.getElementsByTagName("span")[i];
				if (i == ipos) {
					ep.classList.add("active");
				} else {
					ep.classList.remove("active");
				}
			}
			
			if (parseInt(p1.style.left) == 0) {
				p2.style.display = "none";
				for (var i=0; i <this.bgImages.length; i++) {
					var cls = this.bgImages[i];
					p2.classList.remove(cls);
				}
				p2.classList.add(this.bgImages[ipos]);
				
				p2.style.transtion = "";
				p2.style.setProperty("-webkit-transition", "");
				p2.style.left = p1.clientWidth + "px";
				p2.style.transtion = "0.7s left ease";
				p2.style.setProperty("-webkit-transition", "0.7s left ease");
				p2.style.display = "block";
				
				p1.style.left = (-1 * p1.clientWidth) + "px";
				p2.style.left = "0px";
			} else {
				p1.style.display = "none";
				for (var i=0; i <this.bgImages.length; i++) {
					var cls = this.bgImages[i];
					p1.classList.remove(cls);
				}
				p1.classList.add(this.bgImages[ipos]);
				
				p1.style.transtion = "";
				p1.style.setProperty("-webkit-transition", "");
				p1.style.left = p2.clientWidth + "px";
				p1.style.transtion = "0.7s left ease";
				p1.style.setProperty("-webkit-transition", "0.7s left ease");
				p1.style.display = "block";
				
				p2.style.left = (-1 * p2.clientWidth) + "px";
				p1.style.left = "0px";
			}
		},
		linkPage: function(){
			var url = this.links[this.pos];
			this.$router.push({path: url});
		},
		changeImage: function(pos){
			this.pos = pos;
			this.setBgImage(pos);
			clearInterval(this.intervalid);
			this.startBgImage();
		}
	},
	mounted: function(){
		var base = document.getElementsByClassName("businessSlideArea")[0];
		var p1 = base.getElementsByClassName("panel1")[0];
		var p2 = base.getElementsByClassName("panel2")[0];
		p1.classList.add("bgImage1");
		p2.classList.add("bgImage2");
		p1.style.left = "0px";
		p2.style.left = p1.clientWidth + "px";
		p1.style.transtion = "0.7s left ease";
		p1.style.setProperty("-webkit-transition", "0.7s left ease");
		p2.style.transtion = "0.7s left ease";
		p2.style.setProperty("-webkit-transition", "0.7s left ease");
		
		this.startBgImage();
	},
	beforeDestroy: function(){
		clearInterval(this.intervalid);
	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
.businessSlideArea {
	width: 100%;
	
	.slide {
		position: relative;
		width: 100%;
		height: 500px;
		overflow: hidden;
		
		.panel1, .panel2 {
			position: absolute;
			top: 0;
			width: 100%;
			height: 500px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			cursor: pointer;
		}
	}
	
	.guide {
		text-align: center;
		font-size: 22.7px;
		
		span {
			color: #C1C1C1;
			cursor: pointer;
		}
		
		.active {
			color: #000;
		}
	}
	
	.bgImage1 {
		/* 画像ファイルの指定 */
		background-image: url(./assets/cresc_top.jpg);
	}
	.bgImage2 {
		/* 画像ファイルの指定 */
		background-image: url(./assets/medlabo_top.jpg);
	}
	.bgImage3 {
		/* 画像ファイルの指定 */
		background-image: url(./assets/warabee_top.jpg);
	}
	.bgImage4 {
		/* 画像ファイルの指定 */
		background-image: url(./assets/medcube_top.jpg);
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.businessSlideArea {
		.slide {
			height: 312.5px;
			
			.panel1, .panel2 {
				height: 312.5px;
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.businessSlideArea {
		.slide {
			height: 187.5px;
			
			.panel1, .panel2 {
				height: 187.5px;
			}
		}
	}
}
</style>
