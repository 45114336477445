// 会社沿革アイテム用コンポーネント
<template>
	<div class="history_items">
		<div v-bind:class="{'mark2':item.mark == 2, 'mark1':item.mark != 2}" v-bind:style="{ top: ((index * this.size) + item.top) + 'px' }"></div>
		<div class="ym1" v-if="item.year != ''" v-bind:style="{ top: ((index * this.size) + item.top + item.margin + this.margin) + 'px' }">{{ item.year + "年" }}</div>
		<div class="disc1" v-bind:style="{ top: ((index * this.size) + item.top + item.margin + this.margin) + 'px' }">
			{{ item.month + "月" }}　{{ item.title }}
			<div v-if="(item.discription != '')&&(item.discription != undefined)" class="smallsize">{{ item.discription }}</div>
		</div>
	</div>
</template>

<script>
export default {
	props:["item","index"],
	data: function(){
		return {
			isSmartPhone: false,
			margin: 0,
			size: 60
		}
	},
	methods: {
		handleResize: function(e) {
			if (window.matchMedia('(max-width:480px)').matches) {
				// SPの処理
				this.isSmartPhone = true;
				this.margin = -3;
				this.size = 80;
			} else if (window.matchMedia('(max-width:800px)').matches) {
				// TABの処理
				this.isSmartPhone = false;
				this.margin = 0;
				this.size = 60;
			} else {
				// PCの処理
				this.isSmartPhone = false;
				this.margin = 0;
				this.size = 60;
			}
		}
	},
	created: function(){
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('load', (e) => {
			this.handleResize(e);
		});
	}
};
</script>

<style lang="less" scoped>
@import "./less/const.less";
.smallsize {
	font-size: @font-smallsize;
	margin-top: 8px;
	margin-left: 39px;
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.history_items {
		font-size: 12px;
	}
	
	.smallsize {
		font-size: @font-sssize;
		margin-top: 0;
		margin-left: 16px;
		line-height: 14px;
	}
}
</style>
