<template>
  <div class="product_carousel">
    <slick ref="slick" :options="slickOptions">
      <router-link to="/business/cresc"><img src="./assets/cresc_banner.png" class="carousel_img"></router-link>
      <router-link to="/business/medlabo"><img src="./assets/medlabo_banner.png" class="carousel_img"></router-link>
      <router-link to="/business/warabee"><img src="./assets/warabee_banner.png" class="carousel_img"></router-link>
      <router-link to="/business/medcube"><img src="./assets/medcube_banner.png" class="carousel_img"></router-link>
      <router-link to="/business/tanom"><img src="./assets/tanom_banner_green.png" class="carousel_img"></router-link>
    </slick>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import './css/slick/slick.css'
import './css/slick/slick-theme.css'
export default {
  name: 'product_carousel',
  data() {
    return {
      position: -1,
      slickOptions: {
        arraws: true,
        dots: true,
        infinite: true,
        centerMode: true,
        centerPadding: '90px',
        slidesToShow: 3,
        slidesToScroll: 3,
        focusOnSelect: true,
        prevArrow:'<button type="button" class="slick-prev"></button>',
        nextArrow:'<button type="button" class="slick-next"></button>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 800,
            settings: {
              dots: false,
              centerMode: false,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
    }
  },
  components: {
    Slick
  },
	methods: {
		handleResize: function(e){
			var carousel = document.getElementsByClassName("product_carousel")[0];
			var top = ((window.innerHeight - carousel.clientHeight) / 2) - 70;
			console.log('innerHeight=' + window.innerHeight + ' : clientHeight=' + carousel.clientHeight + ' : top=' + top);
			if(top < 0) top = 0;
			carousel.style.top = top + "px";
			//carousel.style.top = (((window.innerHeight - carousel.clientHeight) / 2) + 50) + "px";
		}
	},
	created: function(){
		window.addEventListener('resize', this.handleResize);
	},
	mounted: function(){
		var _self = this;

        this.handleResize();
		
		setTimeout(function () {
			_self.position = 0;
		}, 100);

	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
.product_carousel {
    position: relative;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.product_carousel {
		max-width: 600px;

    .carousel_img {
      margin: 0 auto;
    }
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.product_carousel {
		max-width: 300px;

    .carousel_img {
      margin: 0 auto;
    }
	}
}
</style>
