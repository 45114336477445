<template>
	<div id="recruit-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px; display:flex;">
			<div class="main_area_text">あなたの技術で新しい価値の創造を</div>
		</div>
		<div class="content_area">
			<div class="sub_title">採用情報</div>
			<hr></hr>
			<div class="comment_panel">
				<div class="sub_title">
					メッセージ
				</div>
				<div>
					時代は常に変化し、インフォメーションテクノロジーとそれを取り巻く環境は日々新たなものへと進化し続けています。
					データキューブは、お客様と共にお客様の課題や問題に真摯に向き合い、それらを解決するための情報システムや情報技術を提供しております。<br><br>
					<div style="text-align:center; font-weight:bold;">
					データキューブの進化の原動力<br>
					それは、「人」です。<br>
					社員個々の持つ「ひたむきさ」と「好奇心」が技術を高め、時代に対応した「新しい価値」を生みだします。<br><br>
					</div>
					「データが未来を切り開く」これはデータキューブから発信しているメッセージであるとともに、自分たち1人ひとりが生み出す「新しい価値」によって創り上げる未来に対するテーマでもあります。<br>
					また、社員は多くの場合「チーム」の中でタスクを行います。その中でも社員一人ひとりが「自分の可能性」を信じ、「自分らしさ」を保つことができる、そのような「社員一人ひとりがもつ多様性を受容できる柔軟で感性豊かな集団」であることを目指します。<br>
					皆様はデータキューブで共に進み続ける仲間となる方々です。お会いできる際は気負わず「自分らしい自分」「等身大の自分」をお見せください。<br>
					皆様にお会いできることを楽しみにしています。<br><br>
					<div style="text-align:right;">データキューブ採用担当一同</div>
				</div>
				<div class="sub_title">
					募集要項
				</div>
				<table class="tblinfo">
					<tr>
						<td class="infohead reqhead">職種</td>
						<td class="infoval" style="background-color:#EBFCE2">システムエンジニア ／ プログラマー ／ フィールドサービスエンジニア ／ システム営業</td>
					</tr>
					<tr>
						<td class="infohead reqhead">応募資格</td>
						<td class="infoval">大学院・大学・高専・専門学校・短大 ／ システム開発経験者・営業経験者</td>
					</tr>
					<tr>
						<td class="infohead reqhead">勤務地</td>
						<td class="infoval">福岡本社 ／ 沖縄営業所 ／ 鹿児島営業所 ／ 宮崎営業所 ／ 千葉開発室</td>
					</tr>
					<tr>
						<td class="infohead reqhead">勤務時間</td>
						<td class="infoval">9:00 ～ 18:00</td>
					</tr>
					<tr>
						<td class="infohead reqhead">残業</td>
						<td class="infoval">あり</td>
					</tr>
					<tr>
						<td class="infohead reqhead">休日</td>
						<td class="infoval">土 ／ 日 ／ 祝祭日</td>
					</tr>
					<tr>
						<td class="infohead reqhead">有給休暇</td>
						<td class="infoval">あり</td>
					</tr>
					<tr>
						<td class="infohead reqhead">他休暇</td>
						<td class="infoval">夏季休暇 ／ 年末年始休暇</td>
					</tr>
					<tr>
						<td class="infohead reqhead">給与</td>
						<td class="infoval">
							203,000円～350,000円<br>
							（30時間のみなし残業手当含む）<br>
						</td>
					</tr>
					<tr>
						<td class="infohead reqhead">諸手当</td>
						<td class="infoval">職能手当 ／ 臨時職務手当 ／ 在宅勤務手当</td>
					</tr>
					<tr>
						<td class="infohead reqhead">交通費</td>
						<td class="infoval">実費相当分を支給</td>
					</tr>
					<tr>
						<td class="infohead reqhead">昇給</td>
						<td class="infoval">年１回</td>
					</tr>
					<tr>
						<td class="infohead reqhead">賞与</td>
						<td class="infoval">年２回　夏季 ／ 冬季</td>
					</tr>
					<tr>
						<td class="infohead reqhead">諸制度</td>
						<td class="infoval">在宅勤務制度 ／ 資格取得支援制度</td>
					</tr>
					<tr>
						<td class="infohead reqhead">福利厚生</td>
						<td class="infoval">健康診断 ／ 慶弔金 ／ 年末納会</td>
					</tr>
					<tr>
						<td class="infohead reqhead">保険</td>
						<td class="infoval">雇用保険 ／ 労災保険 ／ 社会保険 ／ 厚生年金</td>
					</tr>
					<tr>
						<td class="infohead reqhead">教育研修</td>
						<td class="infoval">新人導入オリエンテーション ／ OJT ／ 技術部研修 ／ 各種セミナー</td>
					</tr>
					<tr>
						<td class="infohead reqhead">その他</td>
						<td class="infoval">上級医療情報技師 ／ 医療情報技師 保有者優遇</td>
					</tr>
				</table>
				<div>
					<router-link to="/humanrights"><span style="font-weight:bold; color:#49AB44">■人権基本方針</span></router-link>
				</div>				
				<div>
					当社では、あらゆる事業活動において、基本的人権を尊重し、国籍、性別、年齢、出身、世系、社会的身分、 信条、宗教、身分、身体的特徴、障がいの有無、ジェンダーアイデンティティ、性的指向、妊産婦などによる不当な差別をおこないません。
				</div>
				<div class="sub_title">
					最近の採用状況
				</div>
				<table class="tblinfo">
					<tr>
						<td class="infohead">内訳</td><td class="infohead">2020年</td><td class="infohead">2021年</td><td class="infohead">2022年</td>
					</tr>
					<tr>
						<td class="infohead">男性</td><td>1</td><td>0</td><td>1</td>
					</tr>
					<tr>
						<td class="infohead">女性</td><td>1</td><td>0</td><td>1</td>
					</tr>
				</table>
				<div class="sub_title">
					社員卒業校
				</div>
				<div>
					鹿児島大学大学院、佐賀大学大学院、徳島大学大学院、鳥取大学大学院、北九州大学、九州工業大学、宮崎大学、久留米工業大学、北海道情報大学、西日本工業大学、大阪電気通信大学短期大学部、北九州工業高等専門学校、有明工業高等専門学校、日本電子専門学校、専門学校コンピュータ教育学院、KCS鹿児島情報専門学校、国際電子ビジネス専門学校
				</div>
				<div class="sub_title">
					連絡先
				</div>
				<div style="margin-left:60px; margin-bottom:18px;">
					〒812-0011<br>
					福岡市博多区博多駅前1丁目14番16号<br>
					博多駅前センタービル7階<br>
					データキューブ株式会社　採用担当宛<br>
					（担当者： 円城寺一哉）<br>
					TEL: 092-433-3366<br>
					E-MAIL: recruit@datacube.co.jp<br>
					URL: https://www.datacube.co.jp/
				</div>
				<div class="sub_title">
					応募方法
				</div>
				<div style="font-weight:bold; margin-bottom:6px;"># 郵送による応募</div>
				<div style="margin-bottom:18px;">
					採用面接をご希望される方は、弊社にご希望の旨をメールもしくはお電話でご連絡の上、履歴書(写真貼付)、職務経歴書を弊社宛にご郵送下さい。<br>
					書類審査後、改めて後日連絡させて頂きます。
				</div>
				<div class="sub_title">
					採用までの基本ステップ
				</div>
				<div>
					<img src="./assets/recruit_step.png" width="35%"/>
				</div>
				<div class="sub_title">
					採用に関するお問い合わせ
				</div>
				<div style="margin-bottom:18px;">採用に関してのお問い合わせは「<router-link to="/contact?flg=2"><span style="font-weight:bold; color:#49AB44">問い合わせフォーム</span></router-link>」からお問い合わせ頂けます。</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'recruitPage',
	components: {
		"header-bar": headerBar,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#recruit-page {
	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/teamwork.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;

		.main_area_text {
			font-size:24pt;
			font-weight:bold;
			color:white;
			margin:auto;
		}
	}
	
	table {
		border-collapse: collapse;
		border-spacing: 0;
		font-size: @font-defailtsize;
	}
	
	.content_area {
		width: 950px;
		margin: 24px auto;
		line-height: 20.9px;
		
		.comment_panel {
			border-radius: 8px;
			border: 1px solid @border-def;
			background-color: #FAFAFA;
			padding: 12px;
			box-shadow: 1px 1px 6px 1px @shadow-color;
			
			.sub_title {
				.section_bar;
				margin: 18px 0 8px;
				font-weight: bold;
				padding: 2px 6px;
				font-size: @font-defailtsize;
			}
			
			.tblinfo {
				width: 100%;
				
				tr {
					td {
						border-bottom: 1px solid @border-def;
						border-right: 1px solid @border-def;
						text-align: center;
						font-weight: bold;
						padding: 8px 0;
					}
					
					&:first-child {
						td {
							border-top: 1px solid @border-def;
						}
					}
				}
				
				.infohead {
					width: 160px;
					border-left: 1px solid @border-def;
					background-color: @gbcolor-title;
				}

				.reqhead {
					text-align-last: justify;
					padding: 0 40px;
				}

				.infoval {
					text-align: left;
					padding-left: 20px;
				}
			}
		}
		
		.sub_title {
			.section_bar;
			font-weight: bold;
			padding-left: 6px;
		}

		.recruit_step {
			width: 35%;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#recruit-page {
		.content_area {
			width: 650px;
			
			.comment_panel {
				.tblinfo {
					.infohead {
						width: 90px;
					}
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#recruit-page {
		.main_area {
			height: 140px;

			.main_area_text {
				font-size: 16pt;
			}
		}
		
		.content_area {
			width: 300px;
			
			.comment_panel {
				.tblinfo {
					.infohead {
						width: 90px;
					}
				}
			}
		}
	}
}
</style>
