<template>
	<div id="business-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<!-- <product-slide></product-slide> -->
			<div class="head_image"></div>
			<div class="contentArea">
				<div class="table">
					<div class="tblrow">
						<div class="tblcell" style="vertical-align:top">
							<div class="head_title bold_title">弊社のビジネスコンセプト</div>
							<div class="conceptComment">
								　当社は、Cach&eacute;/M言語によるシステム開発を得意としており、当初は生産分野や物流分野のシステム開発も手掛けておりましたが、大規模データベース・マルチユーザーシステムを得意とするCache/M言語の特性を活かして、現在では、主に医療およびその周辺分野における受託開発を中心に事業を行っております。<br>
								　また、当社の主力製品である「医療機関用データウエアハウス」、「電子カルテ・オーダリングシステムを合わせた病院情報システム」等を、大学病院からクリニックまで幅広くご提案・導入しております。
								より良い情報システムを作り上げるという姿勢にこだわりつつ、時代が求める新たなテクノロジーや考え方、ツールを取り入れ、お客様と共に考え、これからもお客様から喜ばれる情報システムを開発してまいります。
							</div>
							<div>
								<div class="head_title"><span class="bold_title">System Integration</span></div>
								<div><div class="bold_title bix_sub_title">◯システムインテグレーション</div>
									<div style="line-height: 20.9px; margin-bottom:16px">
										当社はCach&eacute;/M言語でのシステム開発を得意とし、その技術を基に医療情報関連のシステム開発を行っています。
										時代に即応したフレキシブルなコンピュータシステムの開発及びネットワークシステム、インターネット各種サービスを通して、
										お客様のビジネスチャンスを最大限に活かすシステムソリューションを実現いたします。
									</div>
								</div>
								<div class="head_title"><span class="bold_title">Software</span></div>
								<div><div class="bold_title bix_sub_title">◯医療系ソフトウェア受託開発</div>
									<div style="line-height: 20.9px; margin-bottom:16px">
										当社が受託するソフトウェア開発は、その多くが医療分野です。システムの企画・設計・構築にあたっては、
										各病院の医師や自治体によって異なる様々なニーズに豊かな創造力と分析・判断力で対応しています。
										これからも、常にお客様の声を聞かせていただきながら、より高い次元で満足していただけるサービスを追求してまいります。
									</div>
								</div>
								<div class="head_title"><span class="bold_title">System Consulting</span></div>
								<div><div class="bold_title bix_sub_title">◯システム管理・運用支援</div>
									<div style="line-height: 20.9px; margin-bottom:16px">
										依頼されてから動くのではなく、常に新しい企業提案をし続けていく－。それが当社の開発コンセプトです。
										サービスサポート・メンテナンスについても、ソフトウェア開発会社という特異を生かした迅速な技術サービスとサポートを駆使し、
										IT戦略の有力なパートナーとして常にお客様の視点に立ったトータルソリューションを実現いたします。
									</div>
								</div>
								<div class="head_title"><span class="bold_title">DX Solution</span><router-link to="/business/dxsolution" class="btn btn--blue btn-c detail_btn">詳細</router-link></div>
								<div><div class="bold_title bix_sub_title">◯DXソリューション</div>
									<div style="line-height: 20.9px; margin-bottom:16px">
										働き方改革、人口減少といった社会課題に対応するため、DX（デジタルトランスフォーメーション）が注目されています。
										現場の効率化のために、これまで行ってきたシステム開発だけでなく、安価で即効性のあるソリューションをご提案してまいります。
									</div>
								</div>
							</div>
						</div>
						<div v-if="!isSmartPhone" class="tblcell" style="width: 28px"></div>
						<div v-if="!isSmartPhone" class="tblcell" style="width:200px; vertical-align:top">
							<div class="head_title bold_title">主要製品一覧</div>
							<router-link to="/business/cresc"><img src="./assets/cresc_banner.png"></router-link>
							<router-link to="/business/medlabo"><img src="./assets/medlabo_banner.png"></router-link>
							<router-link to="/business/warabee"><img src="./assets/warabee_banner.png"></router-link>
							<router-link to="/business/medcube"><img src="./assets/medcube_banner.png"></router-link>
							<router-link to="/business/tanom"><img src="./assets/tanom_banner.png"></router-link>
							<div class="comment">※その他多くの開発実績があります</div>
							<div style="height:20px"/>
							<div class="head_title bold_title">取扱DX製品一覧</div>
							<a href="https://fce-pat.co.jp/" target="_brank"><img style="width:200px" src="./assets/robopat.png"/></a>
							<div class="comment">※Robo-Patは(株)FCEプロセス&テクノロジーの製品、及び、登録商標です</div>
							<a href="https://www.celf.biz/" target="_brank"><img style="width:200px" src="./assets/celf.png"/></a>
							<div class="comment">※CELFはSCSK(株)の製品です</div>
							<!-- <a href="https://toruno.biz/" target="_brank"><img style="width:200px" src="./assets/toruno_logo.png"/></a>
							<div class="comment">※torunoはリコージャパン(株)の製品です</div> -->
							<a href="https://dx-suite.com/" target="_brank"><img style="width:200px" src="./assets/dxsuite_logo.png"/></a>
							<div class="comment">※DX SuiteはAI inside(株)の製品です</div>
						</div>
					</div>
				</div>
				<div v-if="isSmartPhone" class="table" style="margin-top:22px">
					<div class="tblrow">
						<div class="tblcell" style="text-align:center">
							<div class="head_title bold_title">主要製品一覧</div>
							<router-link to="/business/cresc"><img src="./assets/cresc_banner.png"></router-link>
							<router-link to="/business/medlabo"><img src="./assets/medlabo_banner.png"></router-link>
							<router-link to="/business/warabee"><img src="./assets/warabee_banner.png"></router-link>
							<router-link to="/business/medcube"><img src="./assets/medcube_banner.png"></router-link>
							<router-link to="/business/tanom"><img src="./assets/tanom_banner.png"></router-link>
							<div class="comment">※その他多くの開発実績があります。</div>
							<div class="head_title bold_title">取扱DX製品一覧</div>
							<a href="https://fce-pat.co.jp/" target="_brank"><img style="width:200px" src="./assets/robopat.png"/></a>
							<div class="comment">※Robo-Patは(株)FCEプロセス&テクノロジーの製品、及び、登録商標です</div>
							<a href="https://www.celf.biz/" target="_brank"><img style="width:200px" src="./assets/celf.png"/></a>
							<div class="comment">※CELFはSCSK(株)の製品です</div>
							<!-- <a href="https://toruno.biz/" target="_brank"><img style="width:200px" src="./assets/toruno_logo.png"/></a>
							<div class="comment">※torunoはリコージャパン(株)の製品です</div> -->
							<a href="https://dx-suite.com/" target="_brank"><img style="width:200px" src="./assets/dxsuite_logo.png"/></a>
							<div class="comment">※DX SuiteはAI inside(株)の製品です</div>
						</div>
					</div>
				</div>
				<div class="product">
					<router-link to="/product" class="btn btn--green btn-c product_btn">製品紹介はこちら</router-link>
					<router-link to="/business/casestudy" class="btn btn--green btn-c product_btn">導入事例はこちら</router-link>
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import productSlide from "./cp_businessSlide.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'businessPage',
	components: {
		"header-bar": headerBar,
		"product-slide": productSlide,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	data: function(){
		return {
			isSmartPhone: false
		}
	},
	methods: {
		handleResize: function(e) {
			if (window.matchMedia('(max-width:480px)').matches) {
				// SPの処理
				this.isSmartPhone = true;
			} else if (window.matchMedia('(max-width:800px)').matches) {
				// TABの処理
				this.isSmartPhone = false;
			} else {
				// PCの処理
				this.isSmartPhone = false;
			}
		}
	},
	created: function(){
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('load', (e) => {
			this.handleResize(e);
		});
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#business-page {	
	.main_area {
		.head_image {
			height: 200px;
			background-color: @bgcolor-main;
			/* 画像ファイルの指定 */
			background-image: url(./assets/business.jpg);
			/* 画像を常に天地左右の中央に配置 */
			background-position: center center;
			/* 画像をタイル状に繰り返し表示しない */
			background-repeat: no-repeat;
			/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
			background-size: cover;
		}
		.contentArea {
			width: 950px;
			margin: 22px auto 20px;
			
			.conceptComment {
				// border: 1px solid #C1C1C1;
				// border-radius: 16px;
				// padding: 16px;
				// box-shadow: 3px 3px 8px 2px rgba(0,0,0,0.1);
				margin-bottom: 32px;
				line-height: 20.9px;
			}
			
			.head_title {
				.section_bar;
				.sub_title;
				padding: 8px;
				margin-bottom: 12px;
			}
			
			.bix_sub_title {
				margin-bottom: 8px;
			}
			
			.comment {
				margin-top: 3px;
				font-size: @font-sssize;
			}

			.product {
				margin-top: 3.0em;
				display: flex;
				justify-content: center;
			}

			.product_btn {
				padding-left: 5.0em;
				padding-right: 5.0em;
				margin: 0 2.0em;
			}

			.detail_btn {
				margin: 0 1.0em;
			}
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#business-page {
		.main_area {
			.contentArea {
				width: 650px;

				.product_btn {
					padding-left: 4.0em;
					padding-right: 4.0em;
					margin-bottom: 1.0em;
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#business-page {
		.main_area {
			.contentArea {
				width: 300px;

				.product {
					display: block;
				}

				.product_btn {
					padding-left: 4.0em;
					padding-right: 4.0em;
					margin-bottom: 1.0em;
				}
			}
		}
	}
}
</style>
