<template>
  <div id="dxsolution-page">
    <div>
      <header-bar></header-bar>
    </div>
    <div class="main_area" style="margin-top:76px">
      <div class="navi_area"><span class="navi_bar_link"><router-link to="/">TOP</router-link> &gt; <router-link to="/business">BUSINESS</router-link> &gt;</span> DXソリューション</div>
      <div class="head_image"></div>
      <div class="contentArea">
        <biz-dx-menu></biz-dx-menu>
				<div style="margin-top:30px">
					<a name="needs" style="position:relative; top:-40px"></a>
					<div class="contentBlock">
						<div class="head_title bold_title">ニーズ</div>
						<div>
							本来ならば基幹システムに機能追加やカスタマイズを行いシステムを処理したいと考えていても、以下のような課題がよく見られます。
						</div>
						<ul>
							<li>予算の関係で機能追加やカスタマイズを断念</li>
							<li>機能追加・カスタマイズ部分に該当する業務に携わる人員が少なく申請しづらい</li>
							<li>ちょっとの手間を我慢すれば良いと考えている</li>
							<li>基幹システムのメーカーがサポートを終了している</li>
						</ul>
						<div class="conceptComment">
							仕事の効率化や業務改善のスピードを妨げている作業が、組織のいたるところに存在しています。<br/>
							我々のDXソリューションは、そのような課題をハイスピード、且つ、低コストで解決することが可能です。
						</div>
						<div class="box28">
							<div class="box-title">病院における診療の事務的付随作業例</div>
							<ul>
								<li>院内文書のスキャン作業や転記作業</li>
								<li>目的別の文章、データのファイリング、バックアップ</li>
								<li>電子カルテデータと院内文書の紐づけ</li>
								<li>電子カルテや他システムからのデータ抽出と紐づけ</li>
								<li>検査システムから必要なデータを抽出し整形後、関係者に配信</li>
								<li>問診や診断、検査等の実施チェックに関わる事務作業</li>
								<li>院内書類・データの関係部署への配信</li>
							</ul>
						</div>
					</div>
					<a name="angle" style="position:relative; top:-40px"></a>
					<div class="contentBlock">
						<div class="head_title bold_title">アングル</div>
						<div class="highlowmix_title">High-Low mix （ハイ・ローミックス）</div>
						<div>High low mixとは米軍の兵器配備経済構想の概念</div>
						<ul>
							<li>高額高性能な兵器と比較的定額低性能な兵器を組み合わせ戦力を構成する</li>
							<li>予算的にも供給力的にも限りがある以上、高額高性能な兵器だけを揃えておくことはできない</li>
							<li>戦略的、戦術的に数が必要な状況もあるため、比較的定額低性能の兵器で数を補う</li>
						</ul>
						<div class="highlowmix_drow_area">
						<div class="highlowmix_drow_title">DXはハイローミックスで</div>
						<div style="display:flex; flex-direction:row; align-items:center;">
							<div class="box10 box10_size">
								<div class="highlow">ハイ（High）</div>
								<div>基幹システム・主要業務システムの導入・機能追加・カスタマイズ</div>
							</div>
							<div>
							<div class="mix mix_size">Mix</div>
							</div>
							<div class="box10 box10_size">
								<div class="highlow">ロー（Low）</div>
								<div>新しいローコストな技術の活用</div>
							</div>
						</div>
						<div style="margin: 1em 0">
							<i class="fas fa-clipboard-check fa-3x"></i>
							<span style="font-size: 17.6px; font-weight:bold">IT技術のハイ・ローミックスで予算と改善スピードの問題を解決！</span>
						</div>
						</div>
					</div>
					<a name="rpa" style="position:relative; top:-40px"></a>
					<div class="contentBlock">
						<div class="head_title bold_title">RPAシステム</div>
						<div class="dx_sub_title">RPAとは</div>
						<img v-if="!isSmartPhone" src="./assets/rpa_image01.png" alt="RPA" style="width: 200px; height: auto; margin: 0 20px; float: right;" />
						<div class="contentBlock_sub">
							RPA（ロボティック・プロセス・オートメーション）とは、ソフトウェアロボットまたは業務プロセス自動化技術。<br />
							デスクトップ作業のみに絞ったものをロボティック・デスクトップ・オートメーション（RDA）と呼ぶこともあります。<br />
						</div>
						<div class="dx_sub_title">取扱製品</div>
						<div class="contentBlock_sub">
							<div class="product_title">現場で使えるRPA！「RPA Robo-Pat」</div>
							<div style="display:flex; flex-direction:row; align-items:flex-end;">
								<a href="https://fce-pat.co.jp/" target="_brank"><img src="./assets/robopat.png" alt="Robo-Pat" style="width: 200px;" /></a>
								<div style="margin-left:1em; font-weight:bold;">株式会社FCEプロセス&テクノロジー</div>
							</div>
							<div style="font-size: 0.7em">※Robo-PatはFCEプロセス＆テクノロジー社の登録商標です</div>
							<div style="margin-top: 1em">
								『RPAロボパットDX』は、事務職の方が自分で自分のパソコン業務を自動化することをコンセプトに開発されたRPAツールです。<br/><br/>
								プログラミングの知識がなくても、画像認識機能を利用した直感的な操作性で初心者でもロボ作成ができることができます。<br/><br/>
								1つのライセンスを複数のPCで利用できるフローティングライセンスを標準搭載しています。使用できるPC台数は無制限なので、自分のPCでのリモートワーク、他部署他拠点気軽に利用するなど、使い方は自由自在です。<br/><br/>
								またRPAツールの提供だけなく、すべての導入企業に専属の担当者がつき、導入促進のための各種サポートを提供しておりますが、これらをすべて無料で提供しています。<br/><br/>
								そのため1か月単位の契約が可能ながら継続率も98％以上となっており、継続的な業務効率化・生産性向上を評価いただいている結果、社員2名の中小企業から数万名以上の大企業までさまざまな業種・規模の企業で働き方を変えるパートナーとしてご愛用いただいています。<br/><br/>
								またお試しとして「3ライセンスが1か月間無料」で利用できる「無料トライアル」を提供しており、このトライアルから専属の担当者がつきます。
							</div>
						</div>
						<div class="dx_sub_title">ユースケース</div>
						<div class="contentBlock_sub">
							定期的に電子カルテとレセプトコンピュータシステム、経理からもらった会計システムのアプトプットデータを手入力で繋ぎ合わせてスプレッドシートを作成、これを各部門に配布。<br/><br/>
							RPAロボパットDXを利用することで「電子カルテとレセプトコンピュータシステムおよび会計システムから受け取ったデータの必要箇所データを取り出し、スプレッドシートの指定の形にまとめ、新しいタイトルを付け、各部門の配信先にメールで自動配信という一連の操作」を簡単に自動化できます。
						</div>
						<div class="contentBlock_sub">
							※Robo-Patの製造元・販売元はFCEプロセス＆テクノロジー社です
						</div>
					</div>
					<a name="lowcode" style="position:relative; top:-40px"></a>
					<div class="contentBlock">
						<div class="head_title bold_title">ノーコード・ローコードシステム</div>
						<div class="dx_sub_title">取扱製品</div>
						<div class="contentBlock_sub">
							<div class="product_title">ノーコードの業務システム開発ツール「CELF」</div>
							<div style="display:flex; flex-direction:row; align-items:flex-end;">
								<a href="https://www.celf.biz/" target="_brank"><img src="./assets/celf.png" alt="CELF" style="width: 200px;" /></a>
								<div style="margin-left:1em; font-weight:bold;">SCSK株式会社</div>
							</div>
							<div style="margin-top: 1em">
								某表計算ソフトライクな画面と操作感のノーコード・ローコードシステムのご提案と導入時のサポートをおこないます。<br/>
								お客様の業務を手軽で安価に改善することを目指します。<br/><br/>
								CELFは、表計算ソフトの弱点である
								<ul>
									<li>データの統合</li>
									<li>複数名・複数箇所での同時作業</li>
									<li>閲覧・入力権限の一括管理</li>
									<li>フォームの更新状態の管理</li>
								</ul>
								が行えるシステムをお客様自身で制作できます。<br/><br/>
								弊社では、お客様自身で作成できるよう、下記のサポートを提供いたします。
								<ul>
									<li>CELF導入サポート</li>
									<li>操作スキル向上サポート</li>
									<li>システム制作代行（委託）</li>
									<li>作成代行システムの保守</li>
								</ul>
							</div>
						</div>
						<div class="dx_sub_title">ユースケース</div>
						<div class="contentBlock_sub">
							毎月、複数部署から表計算ソフトで作成したシートを集め、事務部門で手入力集計し、１つのシートにまとめ会議資料を作成する。<br/>
							（表計算ソフトで作成されるシートの様式はたまに変更がある。）
							<br/><br/>
							CELFは、表計算ソフトで作成したシートそのままの様式を利用し、データの集計（統合）、複数名・複数箇所での同時作業が可能なシステムを簡単に製作することができます。<br/>
							また、権限の一括管理、フォームの更新状態の管理などの管理も可能となります。<br/>
							加えて、一部の表計算ソフトやデータベースソフトにみられるサポート期限の問題も解消します。
						</div>
					</div>
					<!-- <a name="aiminutes" style="position:relative; top:-40px"></a>
					<div class="contentBlock">
						<div class="head_title bold_title">AI議事録</div>
						<div class="dx_sub_title">議事録作成支援ソフト</div>
						<div class="contentBlock_sub">
							現在、音声をテキスト化する技術がビジネスシーンの様々な場面で活用されています。<br/>
							この技術をもとに作られたのが議事録作成支援ソフトです。<br/><br/>
							議事録作成はその内容をしっかりしたものにしようとすればするほど、時間を要します。<br/>
							議事録を短時間で作成することができれば、より付加価値を生み出す時間に振り当てることが可能となります。<br/>
							また、音声とともにテキスト化できることによって、記録のの抜けやもれなどがなくなり、記録の質が向上します。<br />
							また、参加者も議事そのものに集中できます。
						</div>
						<div class="dx_sub_title">取扱製品</div>
						<div class="contentBlock_sub">
							<div class="product_title">会議を文字起こし・録音「toruno」</div>
							<div style="display:flex; flex-direction:row; align-items:flex-end;">
								<a href="https://toruno.biz/" target="_brank"><img src="./assets/toruno_logo.png" alt="TORUNO" style="width: 200px;" /></a>
								<div style="margin-left:1em; font-weight:bold;">リコージャパン株式会社</div>
							</div>
						</div>
						<div class="dx_sub_title">導入メリット</div>
						<div class="contentBlock_sub">
							業務効率化ツールを活用し、付帯業務時間を短縮することで、付加価値業務の時間に振り当てることができます。<br/><br/>

							議事録作成支援ソフトは様々ありますが、
							<ul>
								<li>発言内容を音声とともにテキストとして記録する（会議のエビデンスとして）</li>
								<li>オンライン会議対応機能がある</li>
								<li>会議の中で決められたタスクを抽出する機能がある</li>
								<li>専門用語のデータベースがある</li>
								<li>AI技術で、発言内容を要約、議事録のベースを作成する</li>
							</ul>
							などによって、機能面と価格の違いがあります。
						</div>
					</div> -->
					<a name="aiocr" style="position:relative; top:-40px"></a>
					<div class="contentBlock">
						<div class="head_title bold_title">AI-OCR</div>
						<div class="dx_sub_title">AI-OCRとは</div>
						<div class="contentBlock_sub">
							AI-OCR（Artificial Intelligence Optical Character Recognition）は、人工知能を用いた光学文字認識技術のことです。<br />
							これは、画像やスキャンされた文書などの文字を自動的に読み取り、コンピューターが処理できる文字データに変換する技術です。<br />
							機械学習やディープラーニングなどの技術を活用して、文字や言語のパターンを学習し、高い精度で文字を識別します。<br/><br/>
							AI-OCR は優れた技術とはいえ１００％の文字変換ではありません。<br/>
							しかし業務の時間短縮が必要な現代にあって、紙に記載された情報をすべて人の手で入力するよりも、OCRで読取りAI文字認識処理を経て文字化された情報をチェックする方法が脚光を浴びています。
						</div>
						<div class="dx_sub_title">取扱製品</div>
						<div class="contentBlock_sub">
							<!-- <div class="product_title">経理業務デジタル化ソリューション「RICOH 受領請求書サービス」</div>
							<div style="display:flex; flex-direction:row; align-items:flex-end;">
								<a href="https://www.ricoh.co.jp/products/list/ricoh-invoice-received-service/" target="_brank"><img src="./assets/ricoh_logo.png" alt="RICOH" style="width: 150px;" /></a>
								<div style="margin-left:1em; font-weight:bold;">リコージャパン株式会社</div>
							</div>
							<div class="product_title">仕入管理業務デジタル化ソリューション「RICOH 受領納品書サービス」</div>
							<div style="display:flex; flex-direction:row; align-items:flex-end;">
								<a href="https://www.ricoh.co.jp/products/list/ricoh-delivery-slip-received-service/" target="_brank"><img src="./assets/ricoh_logo.png" alt="CELF" style="width: 150px;" /></a>
								<div style="margin-left:1em; font-weight:bold;">リコージャパン株式会社</div>
							</div>
							<div class="product_title">コンテンツ管理サービス「DocuWare」</div>
							<div style="display:flex; flex-direction:row; align-items:flex-end;">
								<a href="https://www.pfu.ricoh.com/docuware/" target="_brank"><img src="./assets/docuware_logo.png" alt="DOCUWARE" style="width: 200px;" /></a>
								<div style="margin-left:1em; font-weight:bold;">株式会社PFU</div>
							</div>
							<div class="product_title">AI-OCRソフトウェア「DynaEye 11」</div>
							<div style="display:flex; flex-direction:row; align-items:flex-end;">
								<a href="https://www.pfu.ricoh.com/dynaeye/product/dynaeye11/" target="_brank"><img src="./assets/dynaeye.png" alt="CELF" style="width: 200px;" /></a>
								<div style="margin-left:1em; font-weight:bold;">株式会社PFU</div>
							</div> -->
							<div class="product_title">AI-OCR市場シェアNo.1「DX Suite」</div>
							<div style="display:flex; flex-direction:row; align-items:flex-end;">
								<a href="https://dx-suite.com/" target="_brank"><img src="./assets/dxsuite_logo.png" alt="CELF" style="width: 200px;" /></a>
								<div style="margin-left:1em; font-weight:bold;">AI inside 株式会社</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
    <div class="footerArea">
      <site-map></site-map>
      <copy-right></copy-right>
    </div>
    <gotop-button></gotop-button>
  </div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import siteMap from "./cp_site_map.vue"
import copyRight from "./cp_copy_right.vue"
import bizDxMenu from "./cp_businessDxMenu.vue"
import gotopButton from "./cp_gotop.vue"
export default {
  name : 'dxsolutionPage',
  components: {
    "header-bar": headerBar,
    "biz-dx-menu": bizDxMenu,
    "site-map": siteMap,
    "copy-right": copyRight,
    "gotop-button": gotopButton
  },
  data: function(){
    return {
      isSmartPhone: false
    }
  },
  methods: {
    handleResize: function(e) {
      if (window.matchMedia('(max-width:480px)').matches) {
        // SPの処理
        this.isSmartPhone = true;
      } else if (window.matchMedia('(max-width:800px)').matches) {
        // TABの処理
        this.isSmartPhone = false;
      } else {
        // PCの処理
        this.isSmartPhone = false;
      }
    }
  },
  created: function(){
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('load', (e) => {
      this.handleResize(e);
    });
  },
  mounted: function(){
    document.body.classList.remove("body");
  }
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#dxsolution-page {
  .main_area {
    .navi_area {
      width: 950px;
      margin: 22px auto 16px;
    }

    .head_image {
      height: 200px;
      background-color: @bgcolor-main;
      /* 画像ファイルの指定 */
			background-image: url(./assets/dxsolution.jpg);
			/* 画像を常に天地左右の中央に配置 */
			background-position: center center;
			/* 画像をタイル状に繰り返し表示しない */
			background-repeat: no-repeat;
			/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
			background-size: cover;
    }

    .contentArea {
			width: 950px;
			margin: 22px auto 20px;
			
			.contentBlock {
				margin-bottom: 3em;
			}

			.contentBlock_sub {
				margin: 2em 0;
			}
			
			.head_title {
				.section_bar;
				.sub_title;
				padding: 8px;
				margin-bottom: 12px;
			}
			
			.box28 {
					position: relative;
					margin: 2em 0;
					padding: 25px 10px 7px;
					border: solid 2px #87CEEB;
			}
			.box28 .box-title {
					position: absolute;
					display: inline-block;
					top: -2px;
					left: -2px;
					padding: 0 9px;
					height: 25px;
					line-height: 25px;
					font-size: @font-lagesize;
					background: #87CEEB;
					color: #ffffff;
					font-weight: bold;
			}

			.box10 {
					color: #00BCD4;
					background: #e4fcff;/*背景色*/
					border-top: solid 6px #1dc1d6;
					box-shadow: 0 3px 4px rgba(0, 0, 0, 0.32);/*影*/
			}

			.box10_size {
					padding: 0.5em 1em;
					margin: 0.5em 0;
					width: 300px;
					min-height: 100px;
			}

			.highlowmix_title {
				font-size: @font-titlesize;
				padding: 1rem 2rem;
				color: #fff;
				background-image: linear-gradient( 135deg, #3C8CE7 10%, #00EAFF 100%);
				margin-bottom: 1rem;
			}

			.highlowmix_drow_area {
				width: fit-content;
			}

			.highlowmix_drow_title {
				.bold_title;
				font-size: @font-bigsize;
				margin: 0 auto;
				width:fit-content;

				position: relative;
				padding: 1rem .5rem;
			}

			.highlowmix_drow_title:after,
			.highlowmix_drow_title:before {
				position: absolute;
				left: 0;
				width: 100%;
				height: 4px;
				content: '';
				background-color: steelblue;
				// background-image: -webkit-gradient(linear, right top, left top, from(#30cfd0), to(#330867));
				// background-image: -webkit-linear-gradient(right, #30cfd0 0%, #330867 100%);
				// background-image: linear-gradient(to left, #30cfd0 0%, #330867 100%);
			}

			.highlowmix_drow_title:before {
				top: 0;
			}

			.highlowmix_drow_title:after {
				bottom: 0;
			}

			.highlow {
				text-align:center;
				font-size: @font-bigsize;
				font-weight: bold;
				margin: 0.5em 0;
			}

			.mix {
				text-align:center;
				font-size: @font-bigsize;
				font-weight: bold;
				color: @white-textcolor;
				border-radius: 50%;
				background-color: #D61E65;
			}

			.mix_size {
				margin: 1em;
				width: 5rem;
				height: 5rem;
				line-height: 5rem;
			}

			.dx_sub_title {
				font-size: @font-lagesize;
				font-weight: bold;
				border-bottom: 3px solid #00BCD4;
				width: fit-content;
			}

			.product_title {
				font-size: @font-lagesize;
				font-weight: bold;
				margin: 1em 0 0 0;
			}

		}
  }

}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#dxsolution-page {
		.main_area {
			.navi_area {
				width: 650px;
			}

			.contentArea {
				width: 650px;

				.box10_size {
						padding: 0.5em 1em;
						margin: 0.5em 0;
						width: auto;
						min-height: auto;
				}

			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#dxsolution-page {
		.main_area {
			.navi_area {
				width: 300px;
				margin-top: 90px;
			}

			.contentArea {
				width: 300px;

				.highlowmix_drow_area {
					width: auto;
				}

				.box10_size {
						padding: 0.5em 1em;
						margin: 0.5em 0;
						width: auto;
						min-height: auto;
				}

				.mix_size {
					font-size: @font-titlesize;
					margin: .5em;
					width: 3rem;
					height: 3rem;
					line-height: 3rem;
				}

			}
		}
	}
}
</style>