<template>
	<div class="contentsMenu">
		<div class="contentMenuArea">
			<div class="contentMenu" v-bind:class="{'active': isActive[0]}" @mouseover="moveUnderBar(1)"><router-link to="/company">会社概要</router-link></div>
			<div class="contentMenu" v-bind:class="{'active': isActive[1]}" @mouseover="moveUnderBar(2)"><router-link to="/company/philosophy">経営理念</router-link></div>
			<div class="contentMenu" v-bind:class="{'active': isActive[2]}" @mouseover="moveUnderBar(3)"><router-link to="/company/history">会社沿革</router-link></div>
			<div class="contentMenu" v-bind:class="{'active': isActive[3]}" @mouseover="moveUnderBar(4)"><router-link to="/company/guide">事業所案内</router-link></div>
			<div class="menuUnderbar" v-bind:class="{'menuUnderbar1': isActive[0], 'menuUnderbar2': isActive[1], 'menuUnderbar3': isActive[2], 'menuUndervar4': isActive[3]}"></div>
		</div>
	</div>
</template>

<script>
export default {
	props:["activeNode"],
	data: function(){
		return {
			isActive: [false, false, false, false]
		}
	},
	methods: {
		moveUnderBar: function(num){
			var area = document.getElementsByClassName('contentMenuArea')[0];
			var ac = "active1";
			if (num == 2) {
				ac = "active2";
				area.classList.remove("active1");
				area.classList.remove("active3");
				area.classList.remove("active4");
			} else if (num == 3) {
				ac = "active3";
				area.classList.remove("active1");
				area.classList.remove("active2");
				area.classList.remove("active4");
			} else if (num == 4) {
				ac = "active4";
				area.classList.remove("active1");
				area.classList.remove("active2");
				area.classList.remove("active3");
			} else {
				area.classList.remove("active2");
				area.classList.remove("active3");
				area.classList.remove("active4");
			}
			area.classList.add(ac);
		}
	},
	mounted: function(){
		var area = document.getElementsByClassName('contentMenuArea')[0];
		if (this.activeNode == 2) {
			this.isActive = [false, true, false, false];
			area.classList.add("active2");
		} else if (this.activeNode == 3) {
			this.isActive = [false, false, true, false];
			area.classList.add("active3");
		} else if (this.activeNode == 4) {
			this.isActive = [false, false, false, true];
			area.classList.add("active4");
		} else {
			this.isActive = [true, false, false, false];
			area.classList.add("active1");
		}
	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
.contentsMenu {
	position: relative;
	bottom: 0;
	width: 100%;
	background-color: @bgcolor-white;
	
	.contentMenuArea {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: 950px;
		height: 37px;
		
		.contentMenu {
			float: left;
			width: 25%;
			text-align: center;
			padding: 12px 0;
			font-size: @font-lagesize;
		}
		
		.menuUnderbar {
			position: relative;
			bottom: -34px;
			width: 237.5px;
			height: 3px;
			background-color: @main-red;
		}
		
		.menuUnderbar1 {
			left: 0px;
			transtion: 0.7s left ease;
			-webkit-transition: 0.7s left ease;
		}
		
		.menuUnderbar2 {
			left: 237.5px;
			transtion: 0.7s left ease;
			-webkit-transition: 0.7s left ease;
		}
		
		.menuUnderbar3 {
			left: 475px;
			transtion: 0.7s left ease;
			-webkit-transition: 0.7s left ease;
		}

		.menuUnderbar4 {
			left: 712.5px;
			transtion: 0.7s left ease;
			-webkit-transition: 0.7s left ease;
		}
	}
	
	.active1 {
		&:hover {
			.menuUnderbar {
				left: 0;
			}
		}
	}
	
	.active2 {
		&:hover {
			.menuUnderbar {
				left: 237.5px;
			}
		}
	}
	
	.active3 {
		&:hover {
			.menuUnderbar {
				left: 475px;
			}
		}
	}

	.active4 {
		&:hover {
			.menuUnderbar {
				left: 712.5px;
			}
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.contentsMenu {		
		.contentMenuArea {
			width: 650px;
			
			.menuUnderbar {
				width: 162.5px;
			}
			
			.menuUnderbar2 {
				left: 162.5px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}
			
			.menuUnderbar3 {
				left: 325px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}

			.menuUnderbar4 {
				left: 487.5px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}
		}
		
		.active1 {
			&:hover {
				.menuUnderbar {
					left: 0;
				}
			}
		}
		
		.active2 {
			&:hover {
				.menuUnderbar {
					left: 162.5px;
				}
			}
		}
		
		.active3 {
			&:hover {
				.menuUnderbar {
					left: 325px;
				}
			}
		}

		.active4 {
			&:hover {
				.menuUnderbar {
					left: 487.5px;
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.contentsMenu {
		.contentMenuArea {
			width: 300px;
			
			.menuUnderbar {
				width: 75px;
			}
			
			.menuUnderbar2 {
				left: 75px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}
			
			.menuUnderbar3 {
				left: 150px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}

			.menuUnderbar4 {
				left: 225px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}			
		}
		
		.active1 {
			&:hover {
				.menuUnderbar {
					left: 0;
				}
			}
		}
		
		.active2 {
			&:hover {
				.menuUnderbar {
					left: 75px;
				}
			}
		}
		
		.active3 {
			&:hover {
				.menuUnderbar {
					left: 150px;
				}
			}
		}

		.active4 {
			&:hover {
				.menuUnderbar {
					left: 225px;
				}
			}
		}		
	}
}
</style>
