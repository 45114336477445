<template>
	<div class="header_bar0" v-bind:class="{'header_bar2': isSpreaded}">
		<div class="header_bar">
			<router-link to="/"><img src="./assets/sign.png"/></router-link>
			<div class="menus" v-bind:class="{'menus2': isSmartPhone}"><router-link to="/company">COMPANY</router-link> | <router-link to="/business">BUSINESS</router-link> | <router-link to = "/product">PRODUCT</router-link> | <router-link to="/recruit">RECRUIT</router-link> | <router-link to="/contact">CONTACT</router-link></div>
		</div>
	</div>
</template>

<script>
export default {
	data: function(){
		return {
			isSpreaded: false,
			isSmartPhone: false
		}
	},
	methods: {
		handleScroll() {
			if (!this.isSpreaded) {
				if (window.scrollY > 50) {
					this.isSpreaded = true;
				}
			} else {
				if (window.scrollY < 10) {
					this.isSpreaded = false;
				}
			}
		},
		handleResize: function(e) {
			if (window.matchMedia('(max-width:480px)').matches) {
				// SPの処理
				this.isSmartPhone = true;
			} else if (window.matchMedia('(max-width:800px)').matches) {
				// TABの処理
				this.isSmartPhone = false;
			} else {
				// PCの処理
				this.isSmartPhone = false;
			}
		}
	},
	created: function(){
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('scroll', this.handleScroll);
	},
	mounted() {
		this.handleResize();
	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
.header_bar0 {
	font-size: @font-lagesize;
	position:fixed; width:100%;
	top: 0;
	z-index: 9000;
	background-color: rgba(255, 255, 255, 1);
	transtion: 0.7s all ease;
	-webkit-transition: 0.7s all ease;
	
	.header_bar {
		width: 950px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 12px;
		margin-bottom: 12px;
		transtion: 0.7s all ease;
		-webkit-transition: 0.7s all ease;
		
		.menus {
			float: right;
			margin-top: 20px;
			font-size: @font-defailtsize;
			transtion: 0.7s all ease;
			-webkit-transition: 0.7s all ease;
		}
		
		.menus2 {
			font-size: 12px;
		}
		
		img {
			width: 20%;
			transtion: 0.7s all ease;
			-webkit-transition: 0.7s all ease;
		}
	}
}

.header_bar2 {
	font-size: @font-lagesize;
	background-color: rgba(255, 255, 255, 0.9);
	border-bottom: 1px solid @border-def;
	
	.header_bar {
		margin-top: 2px;
		margin-bottom: 2px;
		
		.menus {
			margin-top: 16px;
			font-size: @font-defailtsize;
		}
		
		img {
			width: 15%;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.header_bar0 {
		.header_bar {
			width: 650px;
			
			.menus {
				font-size: @font-defailtsize;
				margin-top: 12px;
				transtion: 0.7s all ease;
				-webkit-transition: 0.7s all ease;
			}
		}
	}
	
	.header_bar2 {
		.header_bar {
			width: 650px;
			margin-top: 2px;
			margin-bottom: 2px;
			
			.menus {
				font-size: @font-defailtsize;
				margin-top: 8px;
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) and (orientation: portrait) {
	.header_bar0 {
		.header_bar {
			width: 350px;
			
			.menus {
				float: none;
				font-size: 12px;
				margin-top: 0;
				transtion: 0.7s all ease;
				-webkit-transition: 0.7s all ease;
			}
			
			img {
				width: 121.8px;
			}
		}
	}
	
	.header_bar2 {
		.header_bar {
			width: 350px;
			margin-top: 2px;
			margin-bottom: 2px;
			
			.menus {
				float: none;
				font-size: 12px;
				margin-top: 0;
			}
			
			img {
				width: 98.6px;
			}
		}
	}
}
</style>
