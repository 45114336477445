<template>
	<div class="news_info">
		<div class="sub_title bold_title">NEWS & INFORMATION</div>
		<div class="tbl" style="width:100%">
			<div class="tblrow">
				<div id="news_info" class="tblcell" style="padding-right: 12px;">
					<topic-items v-for="item in items" :item="item" :key="item.id"></topic-items>
				</div>
				<div class="tblcell sidebannar">
					<a href="http://www.intersystems.com/jp/" target="_brank"><img src="./assets/intersystems_application_partner_blue.png" style="width:150px; height:42px;"/></a>
				</div>
			</div>
		</div>
		<div class="sidebannar_mb">
			<a href="http://www.intersystems.com/jp/" target="_brank"><img src="./assets/intersystems_application_partner_blue.png" style="width:150px; height:42px;"/></a>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import newsitems from "./cp_news_items.vue";
export default {
	components: {
		"topic-items": newsitems
	},
	data: function(){
		return {
			items:[],
			cgipath: "",
			filepath: ""
		};
	},
	methods: {
		getItems() {
			let res = axios.get("/topics.json", {
				timeout: 3000,
				responseType: 'json'
			}).then(res => {
				this.items = res.data;
			}).catch(e => {
				console.log(e);
			});
		}
	},
	created: function(){
		this.getItems();
	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
.news_info {
	width: 950px;
	margin-left: auto;
	margin-right: auto;
	
	.sub_title {
		font-size: @font-lagesize;
	}
	
	.sidebannar {
		width: 200px;
		border-left: 1px solid @border-def;
		padding-left: 8px;
	}
	
	.sidebannar_mb {
		display: none;
	}
}
/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.news_info {
		width: 650px;
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.news_info {
		width: 300px;
		font-size: 12px;
		
		.sidebannar {
			display: none;
		}
		
		.sidebannar_mb {
			display: block;
			text-align: center;
			padding: 20px 0 0;
		}
	}
}
</style>
