<template>
	<div id="humanrights-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px"></div>
		<div class="content_area">
			<div class="comment_panel">
				<div class="sub_title underbar" style="font-weight:bold">人権基本方針</div>
				<div style="text-align:right; margin:6px 0">
					令和5年7月1日最終改訂<br>
					データキューブ株式会社<br>
					代表取締役　上村　慎一郎
				</div>
				<div style="font-weight:bold; margin:12px 0">
					　当社は人間の多様性を尊重し、自由な社会に効果的に参加することを可能にするという目的のもと、
                    当社のすべての従事者が業務に参画する機会を持ち、それぞれの経験や能力、考え方が認められ活かされるように、
                    また安心して業務に従事できるように以下の方針に基づき事業活動をおこないます。
				</div>
				<div style="margin:12px 0">
                    1. 当社では、あらゆる事業活動において、基本的人権を尊重し、国籍、性別、年齢、出身、世系、社会的身分、
                    信条、宗教、身分、身体的特徴、障がいの有無、ジェンダーアイデンティティ、性的指向、妊産婦などによる不当な差別をおこないません。
				</div>
				<div style="margin:12px 0">
					2. 多様な価値観を尊重し、社員一人ひとりの心身の健康や安全、ご家族の事情に配慮した働きやすい職場環境づくりに取り組みます。
				</div>
				<div style="margin:12px 0">
					3. 様々な機会を通じて、人権に関する課題や事業活動との関わりについて、従事者全員が理解を深め人権を尊重するよう、幅広い啓発に努めます。
				</div>
				<div style="margin:12px 0">
					4. 誠実な対話と協議を通じて、信頼関係を構築し、人権尊重に向けたあらゆる取り組みを推進します。
				</div>
				<div style="margin:12px 0">
					5. 職場におけるハラスメントの発生を防止し、発生があった場合には適切な対応をおこないます。
				</div>
                <div style="margin:12px 0">
                    6. 個々人のプライバシーの保護を徹底し、会社が知りうる個人情報の漏洩と社員間でのアウティングもしくはそれに類する言動を禁止します。
                </div>
                <div style="margin:12px 0">
                    7. 安全衛生関連の法令を遵守し、安全で不快感のない働きやすい職場環境づくりを目指します。
                </div>
                <div style="margin:12px 0">
                    8. 相談窓口を設置し、必要に応じ他の相談窓口と連携し、悩みや問題の理解、苦情受付と対応および苦情処理をおこないます。
                </div>
				<div style="margin:42px 0">
					＜問い合わせ先＞<br>
					データキューブ株式会社　人権基本方針　お問い合わせ窓口：円城寺<br>
					TEL：092-433-3366
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'humanrightsPage',
	components: {
		"header-bar": headerBar,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#humanrights-page {
	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/privacy.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}

	.content_area {
		width: 950px;
		margin: 24px auto;
		line-height: 20.9px;

		.comment_panel {
			border-radius: 8px;
			border: 1px solid @border-def;
			background-color: #FAFAFA;
			padding: 12px;
			box-shadow: 1px 1px 6px 1px @shadow-color;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#humanrights-page {
		.content_area {
			width: 650px;
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#humanrights-page {
		.main_area {
			height: 140px;
		}

		.content_area {
			width: 300px;
		}
	}
}
</style>
