<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'app'
}
</script>

<style>
</style>
