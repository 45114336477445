<template>
	<div id="company-guide-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<comp-menus :activeNode="code"></comp-menus>
		</div>
		<div class="content_area">
			<div class="tbl" style="width:100%">
				<div class="tblrow">
					<div v-if="!isSmartPhone" class="tblcell sidelist" style="vertical-align:top; position:relative">
						<div class="tbl compListNavi">
							<div class="tblrow">
								<div class="tblcell" style="position:relative">
									<div class="guide_subtitle">事業所のご案内</div>
									<img class="mapimage" src="./assets/map-japan.jpg" />
								</div>
							</div>
							<div class="tblrow">
								<div class="tblcell" style="height:32px"></div>
							</div>
							<div class="tblrow">
								<div class="tblcell compTitle" style="cursor:pointer" @click="scrollAnswer('fukuoka')">本社</div>
							</div>
							<div class="tblrow">
								<div class="tblcell compTitle" style="cursor:pointer" @click="scrollAnswer('miyazaki')">宮崎営業所</div>
							</div>
							<div class="tblrow">
								<div class="tblcell compTitle" style="cursor:pointer" @click="scrollAnswer('kagoshima')">鹿児島営業所</div>
							</div>
							<div class="tblrow">
								<div class="tblcell compTitle" style="cursor:pointer" @click="scrollAnswer('okinawa')">沖縄営業所</div>
							</div>
							<div class="tblrow">
								<div class="tblcell compTitle" style="cursor:pointer" @click="scrollAnswer('chiba')">千葉開発室</div>
							</div>
						</div>
					</div>
					<div class="tblcell" style="width:12px"></div>
					<div class="tblcell" style="vertical-align:top">
						<div class="section_bar sub_title" style="font-weight:bold; margin:32px 0; padding-left:6px">事業所一覧</div>
						<div class="tbl companyList" style="width:100%">
							<div class="tblrow">
								<div class="tblcell compName"><a class="ankpos" name="fukuoka"></a>本社</div>
								<div class="tblcell compAddress">
									〒812-0011<br>
									福岡市博多区博多駅前1丁目14番16号<br>
									博多駅前センタービル7階<br>
									TEL／092-433-3366　FAX／092-433-3376
									<p v-if="isSmartPhone">
										<a href="https://www.google.com/maps/place/%E3%80%92812-0011+%E7%A6%8F%E5%B2%A1%E7%9C%8C%E7%A6%8F%E5%B2%A1%E5%B8%82%E5%8D%9A%E5%A4%9A%E5%8C%BA%E5%8D%9A%E5%A4%9A%E9%A7%85%E5%89%8D%EF%BC%91%E4%B8%81%E7%9B%AE%EF%BC%91%EF%BC%94%E2%88%92%EF%BC%91%EF%BC%96+%E5%8D%9A%E5%A4%9A%E9%A7%85%E5%89%8D%E3%82%BB%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%93%E3%83%AB/@33.59241,130.418554,15z/data=!4m5!3m4!1s0x354191c655653957:0xc913675774e19b6b!8m2!3d33.592411!4d130.4185605?hl=ja" target="_brank">地図を開く...</a>
									</p>
								</div>
								<div v-if="!isSmartPhone" class="tblcell compMapArea">
									<div class="cmpMap">
										<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.4968803025167!2d130.41855435!3d33.59241005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354191c655653957%3A0xc913675774e19b6b!2z44CSODEyLTAwMTEg56aP5bKh55yM56aP5bKh5biC5Y2a5aSa5Yy65Y2a5aSa6aeF5YmN77yR5LiB55uu77yR77yU4oiS77yR77yWIOWNmuWkmumnheWJjeOCu-ODs-OCv-ODvOODk-ODqw!5e0!3m2!1sja!2sjp!4v1430306636235" width="200" height="200" frameborder="0" style="border:0"></iframe>
									</div>
								</div>
							</div>
							<div class="tblrow">
								<div class="tblcell compName"><a class="ankpos" name="miyazaki"></a>宮崎営業所</div>
								<div class="tblcell compAddress">
									〒880-0811<br>
									宮崎県宮崎市錦町1番10号　KITENビル5階<br>
									TEL／0985-88-3456　FAX／0985-88-3457
									<p v-if="isSmartPhone">
										<a href="https://www.google.com/maps/place/KITEN%E3%83%93%E3%83%AB/@31.916363,131.431351,15z/data=!4m5!3m4!1s0x0:0xf461b4cecb85f72d!8m2!3d31.9163629!4d131.4313506?hl=ja" target="_brank">地図を開く...</a>
									</p>
								</div>
								<div v-if="!isSmartPhone" class="tblcell compMapArea">
									<div class="cmpMap">
										<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3386.640532513646!2d131.42916191519126!3d31.916362881240456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3538b75cca904379%3A0xf461b4cecb85f72d!2zS0lURU7jg5Pjg6s!5e0!3m2!1sja!2sjp!4v1510897401281" width="200" height="200" frameborder="0" style="border:0"></iframe>
									</div>
								</div>
							</div>
							<div class="tblrow">
								<div class="tblcell compName"><a class="ankpos" name="kagoshima"></a>鹿児島営業所</div>
								<div class="tblcell compAddress">
									〒890-0053<br>
									鹿児島県鹿児島市中央町22-16<br>
									アエールプラザ3階<br>
									TEL／099-800-2823　FAX／099-800-2823
									<p v-if="isSmartPhone">
										<a href="https://www.google.com/maps/search/%E3%82%A2%E3%82%A8%E3%83%BC%E3%83%AB%E3%83%97%E3%83%A9%E3%82%B6+%E3%80%92890-0053+%E9%B9%BF%E5%85%90%E5%B3%B6%E7%9C%8C%E9%B9%BF%E5%85%90%E5%B3%B6%E5%B8%82%E4%B8%AD%E5%A4%AE%E7%94%BA%EF%BC%92%EF%BC%92%E2%88%92%EF%BC%91%EF%BC%96/@31.582195,130.54259,15z?hl=ja" target="_brank">地図を開く...</a>
									</p>
								</div>
								<div v-if="!isSmartPhone" class="tblcell compMapArea">
									<div class="cmpMap">
										<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3398.8852214543904!2d130.54259039999997!3d31.582195250000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x353e6745efdf4003%3A0xa948c6773a8950e6!2z44CSODkwLTAwNTMg6bm_5YWQ5bO255yM6bm_5YWQ5bO25biC5Lit5aSu55S677yS77yS4oiS77yR77yWIOOCouOCqOODvOODq-ODl-ODqeOCtg!5e0!3m2!1sja!2sjp!4v1430306535154" width="200" height="200" frameborder="0" style="border:0"></iframe>
									</div>
								</div>
							</div>
							<div class="tblrow">
								<div class="tblcell compName"><a class="ankpos" name="okinawa"></a>沖縄営業所</div>
								<div class="tblcell compAddress">
									〒903-0801<br>
									沖縄県那覇市首里末吉町2丁目19番 2階<br>
									TEL／098-988-3263
									<p v-if="isSmartPhone">
										<a href="https://www.google.com/maps/place/%EF%BC%AA%EF%BC%A2%E3%83%BB%EF%BC%AE%EF%BC%A1%EF%BC%A8%EF%BC%A1%E3%83%93%E3%83%AB/@26.215826,127.678026,15z/data=!4m5!3m4!1s0x0:0xdd9df7769a9daa1d!8m2!3d26.215826!4d127.678026?hl=ja" target="_brank">地図を開く...</a>
									</p>
								</div>
								<div v-if="!isSmartPhone" class="tblcell compMapArea">
									<div class="cmpMap">
										<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3578.9974623851417!2d127.70809885168408!3d26.229271995406766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e56bef768a71ab%3A0xa196b10c259a284a!2z44CSOTAzLTA4MDEg5rKW57iE55yM6YKj6KaH5biC6aaW6YeM5pyr5ZCJ55S677yS5LiB55uu77yR77yZ!5e0!3m2!1sja!2sjp!4v1629961622689!5m2!1sja!2sjp" width="200" height="200" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
									</div>
								</div>
							</div>
							<div class="tblrow">
								<div class="tblcell compName"><a class="ankpos" name="chiba"></a>千葉開発室</div>
								<div class="tblcell compAddress">
									〒273-0005<br>
									千葉県船橋市本町2丁目2番7号<br>
									船橋本町プラザビル7階<br>
									TEL／047-407-2008
									<p v-if="isSmartPhone">
										<a href="https://www.google.com/maps/place/%E3%82%AF%E3%83%AD%E3%82%B9%E3%82%AA%E3%83%95%E3%82%A3%E3%82%B9%E5%86%85%E5%B9%B8%E7%94%BA/@35.667789,139.753257,15z/data=!4m5!3m4!1s0x60188becbbb525b1:0x807210df5631d543!8m2!3d35.6678045!4d139.7532648?hl=ja" target="_brank">地図を開く...</a>
									</p>
								</div>
								<div v-if="!isSmartPhone" class="tblcell compMapArea">
									<div class="cmpMap">
										<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.216692703369!2d139.98262345177375!3d35.696284836654705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60187fd6dd3a6393%3A0x9c68d76b068eebe7!2z44CSMjczLTAwMDUg5Y2D6JGJ55yM6Ii55qmL5biC5pys55S677yS5LiB55uu77yS4oiS77yX!5e0!3m2!1sja!2sjp!4v1629961852518!5m2!1sja!2sjp" width="200" height="200" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import compMenu from "./cp_compMenu.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'companyGuidPage',
	components: {
		"header-bar": headerBar,
		"comp-menus": compMenu,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	data: function(){
		return {
			code: "4",
			isSmartPhone: false
		}
	},
	methods: {
		handleResize: function(e) {
			if (window.matchMedia('(max-width:480px)').matches) {
				// SPの処理
				this.isSmartPhone = true;
			} else if (window.matchMedia('(max-width:800px)').matches) {
				// TABの処理
				this.isSmartPhone = false;
			} else {
				// PCの処理
				this.isSmartPhone = false;
			}
		},
		scrollAnswer: function(name) {
			var element;
			if ('scrollingElement' in document) {
				element = document.scrollingElement;
			} else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
				element = document.body;
			} else {
				element = document.documentElement;
			}
			var anc = document.getElementsByName(name)[0];
			var rect = anc.getBoundingClientRect();
			var top = rect.top + element.scrollTop - 60;
			this.scrollTo(top, 300);
		},

		scrollTo: function(to, duration) {
			var element;
			if ('scrollingElement' in document) {
				element = document.scrollingElement;
			} else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
				element = document.body;
			} else {
				element = document.documentElement;
			}
			if (duration <= 0) return;
			var difference = to - element.scrollTop;
			var perTick = difference / duration * 10;
			var _self = this;

			setTimeout(function () {
				element.scrollTop = element.scrollTop + perTick;
				if (element.scrollTop === to) return;
				_self.scrollTo(to, duration - 10); // 再帰で実行
			}, 10);
		}
	},
	watch: {
		'$route': function (n, o) {
			if (n.hash.match(/^#/)) {
				this.$nextTick(() => {
					document.getElementsByName(n.hash.replace(/^#/, ''))[0].scrollIntoView()
				});
			}
			console.log('new, old', [n.hash, o.hash])
		}
	},
	created: function(){
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('load', (e) => {
			this.handleResize(e);
		});
	},
	mounted: function(){
		document.body.classList.remove("body");
		if (this.$route.hash.match(/^#/)) {
			this.$nextTick(() => {
				document.getElementsByName(this.$route.hash.replace(/^#/, ''))[0].scrollIntoView();
			});
		}
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#company-guide-page {
	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/light.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}

	.content_area {
		width: 950px;
		margin: 24px auto;

		.sidelist {
			width: 220px;
		}

		.mapimage {
			width: 220px;
			height: 174px
		}

		.guide_subtitle {
			position: absolute;
			top: 70px;
			left: 32px;
			font-size: @font-bigsize;
			font-weight: bold;
			color: #A3313B;
			text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
		}
	}

	.compListNavi {
		position:relative;
		top: 32px;

		.tblrow {
			&:nth-child(2) {
				.tblcell {
					border-bottom: 1px solid @border-def;
				}
			}
		}

		.compTitle {
			padding: 12px 6px;
			font-size: @font-lagesize;
			font-weight: bold;
			border-bottom: 1px solid @border-def;

			&:hover {
				background-color: #E7BCC9;
			}
		}
	}

	.companyList {
		.tblrow {
			.tblcell {
				border-bottom: 1px solid @border-def;
			}

			&:first-child {
				.tblcell {
					border-top: 1px solid @border-def;
				}
			}
		}
		.compName {
			width: 120px;
			padding: 22px;
			font-size: @font-lagesize;
			font-weight: bold;
		}

		.compAddress {
			width: 294px;
			padding: 22px;
			line-height: 20.9px;
			height: 182px;
		}

		.compMapArea {
			position: relative;
			padding: 6px 3px;
			vertical-align: top;
		}

		.cmpMap {
			position: absolute;
			border: 1px solid @border-def;
			border-radius: 8px;
			box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
			background-color: #F4F4F4;
			padding: 4px;
		}

		.ankpos {
			margin-top: 60px;

			&::before {
				content: "";
				display: block;
				height: 60px;
				margin-top: -60px;
				visibility: hidden;
			}
		}
	}
}
/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#company-guide-page {
		.content_area {
			width: 650px;

			.sidelist {
				width: 110px;
			}

			.mapimage {
				width: 110px;
				height: 87px
			}

			.guide_subtitle {
				top: 40px;
				left: 4px;
				font-size: 13.6px;
			}
		}

		.companyList {
			.compName {
				width: 90px;
				padding: 16px 0;
				font-size: 12.4px;
			}

			.compAddress {
				width: 224px;
				padding: 16px 0;
				font-size: 10px;
			}

			.cmpMap {
				padding: 0;
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#company-guide-page {
		.main_area {
			height: 140px;
		}

		.content_area {
			width: 300px;

			.mapimage {
				width: 110px;
				height: 87px
			}

			.guide_subtitle {
				top: 40px;
				left: 4px;
				font-size: 13.6px;
			}
		}

		.companyList {
			.compName {
				width: 90px;
				padding: 16px 0;
				font-size: 12.4px;
			}

			.compAddress {
				padding: 16px 0;
				font-size: 10px;
			}

			.cmpMap {
				padding: 0;
			}
		}
	}
}
</style>
