// TANOMの紹介ページ
<template>
	<div class="busi-tanom">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<div class="navi_area"><span class="navi_bar_link"><router-link to="/">TOP</router-link> &gt; <router-link to="/product">PRODUCT</router-link> &gt;</span> TANOM</div>
			<div class="bgImage_area"><img class="bgImage" src="./assets/tanom_top.png" /></div>
			<div class="conetnts_area">
				<biz-menu></biz-menu>
				<div class="description">
					コピー用紙やボールペンなどの文房具、ウェットティッシュなどの衛生用品といった備品・用度品の購入は紙で運用していませんか。『TANOM』なら、これら備品・用度品の請求・受発注をシステムで手軽に管理することができます。
				</div>
				<a name="outline" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div>
					<span class="sub_title section_bar" style="padding:6px">ペーパーレス化の促進に</span>
					<div class="wbpanel1">
						<div class="disc1" style="margin: 32px 0; line-height:20.9px">
							これまで紙で運用することが多く、管理が煩雑であった備品・用度品の請求・受発注をシステムで管理することで、ペーパーレス化を促進します。<br>
							<img class="img1" src="./assets/tanom2.png" />
						</div>
					</div>
					<span class="sub_title section_bar" style="padding:6px">直感的な操作感</span>
					<div class="wbpanel2">
						<div class="disc1" style="margin: 32px 0; line-height:20.9px">
							各部署での請求、用度課での締処理や各仕入先への発注、物品の納品や各部署への払出しといった備品・用度品に関するワークフローを一元的に管理することができます。<br>
							メインページに一連の業務画面への遷移を集約し、軽快な動作を実現することで直感的な操作感を得ることができます。
							<img class="img2" src="./assets/tanom4.png" />
						</div>
					</div>
				</div>
				<a name="function" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div>
					<span class="sub_title section_bar" style="padding:6px">主な機能</span>
					<div>
						<table class="funcList" style="width:100%">
							<tr>
								<td class="title"><div>物品請求</div></td>
								<td class="discription">物品請求登録、請求情報参照</td>
							</tr>
							<tr>
								<td class="title"><div>調整・発注</div></td>
								<td class="discription">集約（締め）登録、発注管理、メール発注、FAX発注用紙印刷</td>
							</tr>
							<tr>
								<td class="title"><div>納品</div></td>
								<td class="discription">納品登録、納品情報参照</td>
							</tr>
							<tr>
								<td class="title"><div>払出</div></td>
								<td class="discription">払出登録、払出情報参照</td>
							</tr>
							<tr>
								<td class="title"><div>各種マスタメンテナンス</div></td>
								<td class="discription">部署、物品、お知らせ、メールテンプレート、業者 etc...</td>
							</tr>
						</table>
					</div>
				</div>
				<a name="request" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div class="request_area">
					<div class="titlesize">資料請求</div>
					<div style="margin-top:32px">簡易受発注システム TANOM に関する資料のお求めや、ご質問等につきましては、以下よりお気軽にお問い合わせ下さい。</div>
					<div class="titlesize button_style" style="margin-top:32px"><router-link to="/contact?flg=1">資料請求はこちら</router-link></div>
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import bizMenu from "./cp_businessMenu.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'busiWarabeePage',
	components: {
		"header-bar": headerBar,
		"biz-menu": bizMenu,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
.busi-tanom {
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	
	.navi_area {
		width: 950px;
		margin: 22px auto 16px;
	}

	.bgImage_area {
		height: 160px;
		text-align:center;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		.bgImage {
			max-height:100%;
			width: auto;
		}
	}

	.conetnts_area {
		width: 950px;
		margin: 22px auto;
		
		.titlesize {
			font-size: @font-titlesize;
		}
		
		.description {
			text-align: center;
			font-size: @font-lagesize;
			line-height: 23px;
			margin: 32px 0;
		}
		
		.wbpanel1 {
			position: relative;
			height: 380px;
			margin: 32px 0 64px;
			background-image: url(./assets/tanom1.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			
			.disc1 {
				position: absolute;
				width: 560px;
				height: 240px;
				right: 32px;
				bottom: 0;
				padding: 12px;
				border-radius: 18px;
				background-color: rgba(0, 0, 0, 0.6);
				color: @white-textcolor;
				box-shadow: 2px 2px 10px 2px @shadow-color;
				line-height: 23px;
				font-size: @font-defailtsize;
			}
		}
		
		.wbpanel2 {
			position: relative;
			height: 380px;
			margin: 32px 0 184px;
			background-image: url(./assets/tanom3.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			
			.disc1 {
				position: absolute;
				width: 560px;
				height: 240px;
				left: 32px;
				bottom: 0;
				padding: 12px;
				border-radius: 18px;
				background-color: rgba(0, 0, 0, 0.6);
				color: @white-textcolor;
				box-shadow: 2px 2px 10px 2px @shadow-color;
				line-height: 23px;
				font-size: @font-defailtsize;
				
				img {
					margin-left:60px
				}
			}
		}
		
		.img1 {
			margin-left: 60px;
		}
		
		.funcList {
			margin-top: 24px;
			font-size: @font-lagesize;
			
			tr {
				&:first-child {
					.title, .discription {
						border-top: 1px solid @border-def;
					}
				}
			}
			
			.title {
				width: 180px;
				text-align: center;
				padding: 12px 0;
				border-bottom: 1px solid @border-def;
				
				div {
					padding: 8px;
					background-color: #B8D999;
				}
			}
			
			.discription {
				padding: 12px;
				border-bottom: 1px solid @border-def;
			}
		}
		
		.request_area {
			margin: 64px;
			text-align: center;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.busi-tanom {
		.navi_area {
			width: 650px;
		}
		
		.bgImage_area {
			height: 103px;

			.bgImage {
				max-width:100%;
				height: auto;
			}
		}
		
		.conetnts_area {
			width: 650px;
			
			.wbpanel2 {
				.disc1 {
					img {
						margin-left:30px
					}
				}
			}
			
			.funcList {
				.title {
					width: 138px;
				}
				
				.discription {
					line-height: 21px;
					height: 43px;
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.busi-tanom {
		.navi_area {
			width: 300px;
			margin-top: 90px;
		}
		
		.bgImage_area {
			height: 103px;

			.bgImage {
				max-width:100%;
				height: auto;
			}
		}
		
		.conetnts_area {
			width: 300px;
			
			.wbpanel1 {
				height: 120px;
				margin: 32px 0 170px;
				
				.disc1 {
					font-size: @font-sssize;
					right: 8px;
					width: 240px;
					height: 100px;
					bottom: -50px;
				}
			}
			
			.wbpanel2 {
				height: 120px;
				margin: 32px 0 170px;
				
				.disc1 {
					font-size: @font-sssize;
					left: 8px;
					width: 240px;
					height: 120px;
					bottom: -80px;
				}
			}
			
			.img1 {
				width: 240px;
				height: 162px;
				margin-left: 4px;
			}
			
			.img2 {
				width: 240px;
				height: 149.5px;
			}
			
			.funcList {
				font-size: @font-sssize;
				
				.title {
					width: 100px;
				}
				
				.discription {
					line-height: 14px;
					height: 38px;
				}
			}
			
			.request_area {
				margin: 64px 0;
				text-align: center;
			}
		}
	}
}
</style>
