<template>
	<div id="company-history-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<comp-menus :activeNode="code"></comp-menus>
		</div>
		<div class="content_area">
			<div class="titlesize">会社沿革</div>
			<div class="historyArea">
				<div class="historyBar"></div>
				<history-items v-for="(item, index) in items" :item="item" :index="index" :key="index"></history-items>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import axios from 'axios';
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import compMenu from "./cp_compMenu.vue"
import gotop from "./cp_gotop.vue"
import history from "./cp_compHistory.vue"
export default {
	name: 'companyHistoryPage',
	components: {
		"header-bar": headerBar,
		"comp-menus": compMenu,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop,
		"history-items": history
	},
	data: function(){
		return {
			items:[],
			code: "3"
		}
	},
	methods: {
		getItems(){
			let res = axios.get("/company_history.json", {
				timeout: 3000,
				responseType: 'json'
			}).then(res => {
				this.items = res.data;
			}).catch(e => {
				console.log(e);
			});
		}
	},
	created: function(){
		this.getItems();
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#company-history-page {
	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/light.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}

	.content_area {
		width: 950px;
		margin: 24px auto;
		font-size: @font-lagesize;
		
		.titlesize {
			.section_bar;
			.sub_title;
			font-weight: bold;
			padding-left: 6px;
			margin-bottom: 15px;
		}
		
		.historyArea {
			position: relative;
			width: 100%;
			height: 1300px;
			
			.historyBar {
				position: absolute;
				top: 2px;
				left: 78px;
				width: 4px;
				height: 1300px;
				background-color:#A1A1A1;
			}
			
			.mark1 {
				position: absolute;
				top: 0px;
				left: 68px;
				width: 24px;
				height: 24px;
				border-radius: 24px;
				box-shadow: 1px 1px 4px 1px @shadow-color;
				z-index: 99;
				background-color:@main-red;
			}
			
			.mark2 {
				position: absolute;
				top: 0px;
				left: 72px;
				width: 16px;
				height: 16px;
				border-radius: 16px;
				box-shadow: 1px 1px 4px 1px @shadow-color;
				z-index: 99;
				background-color:@main-red;
			}
			
			.ym1 {
				position: absolute;
				left: 0;
				top: 5px;
			}
			
			.disc1 {
				position: absolute;
				left: 112px;
				top: 5px;
			}
		}
	}
}
/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#company-history-page {
		.content_area {
			width: 650px;
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#company-history-page {
		.main_area {
			height: 140px;
		}
		
		.content_area {
			width: 300px;
			
			.historyArea {
				height: 1750px;
				
				.historyBar {
					left: 60px;
					height: 1730px;
				}
				
				.mark1 {
					left: 50px;
				}
				
				.mark2 {
					left: 54px;
				}
				
				.disc1 {
					left: 84px;
				}
			}
		}
	}
}
</style>
