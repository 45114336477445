<template>
	<div id="contact-rev-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px"></div>
		<div class="content_area" style="height:480px">
			<h2>送信が完了しました。</h2>
			<div>
				確認の為、ご指定のメールアドレスへ送信内容をお送りしております。<br>
				数分経ってもメールが届かない場合は、メールアドレスをご確認の上、再度送信して下さい。
			</div>
			<div style="padding-top:22px">
				ご利用有難うございました。
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'privacyPage',
	components: {
		"header-bar": headerBar,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#contact-rev-page {
	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/privacy.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}
	
	.content_area {
		width: 950px;
		margin: 24px auto;
		line-height: 20.9px;
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#contact-rev-page {
		.content_area {
			width: 650px;
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#contact-rev-page {
		.main_area {
			height: 140px;
		}
		
		.content_area {
			width: 300px;
		}
	}
}
</style>
