<template>
	<div class="biz_contentsMenu">
		<div class="contentMenuArea">
			<div class="contentMenu" v-for="(menu, index) in menus" v-bind:class="{'active': isActive[index]}" @mouseover="moveUnderBar(index + 1)"><div style="cursor:pointer" @click="scrollAnswer(menu.anker)">{{menu.name}}</div></div>
			<div class="menuUnderbar"></div>
		</div>
	</div>
</template>

<script>
export default {
	props:["activeNode"],
	data: function(){
		return {
			isActive: [],
			menus:[
				{"name":"概要", "anker":"outline"},
				{"name":"機能", "anker":"function"},
				{"name":"資料請求", "anker":"request"}
			]
		}
	},
	methods: {
		moveUnderBar: function(num){
			var area = document.getElementsByClassName('contentMenuArea')[0];
			var ac = "";
			
			for (var i=0; i<this.menus.length; i++) {
				if (num == (i + 1)) {
					ac = "active"+num;
				} else {
					area.classList.remove("active"+(i + 1));
				}
			}
			area.classList.add(ac);
		},
		
		scrollAnswer: function(name) {
			var element;
			if ('scrollingElement' in document) {
				element = document.scrollingElement;
			} else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
				element = document.body;
			} else {
				element = document.documentElement;
			}
			var anc = document.getElementsByName(name)[0];
			var rect = anc.getBoundingClientRect();
			var top = rect.top + element.scrollTop;
			this.scrollTo(top, 300);
		},
		
		scrollTo: function(to, duration) {
			var element;
			if ('scrollingElement' in document) {
				element = document.scrollingElement;
			} else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
				element = document.body;
			} else {
				element = document.documentElement;
			}
			if (duration <= 0) return;
			var difference = to - element.scrollTop;
			var perTick = difference / duration * 10;
			var _self = this;
			
			setTimeout(function () {
				element.scrollTop = element.scrollTop + perTick;
				if (element.scrollTop === to) return;
				_self.scrollTo(to, duration - 10); // 再帰で実行
			}, 10);
		}
	},
	mounted: function(){
		var area = document.getElementsByClassName('contentMenuArea')[0];
		var bar = document.getElementsByClassName('menuUnderbar')[0];
		this.isActive = [];
		var acn = this.activeNode;
		if (acn == undefined) {
			acn = 1;
		}
		for (var i=0; i<this.menus.length; i++) {
			if ((i + 1) == acn) {
				this.isActive.push(true);
				area.classList.add("active"+acn);
				bar.classList.add("menuUnderbar"+acn);
			} else {
				this.isActive.push(false);
			}
		}
	}
}
</script>

<style lang="less" scoped>
@import "./less/const.less";
.biz_contentsMenu {
	position: relative;
	bottom: 0;
	width: 100%;
	background-color: @bgcolor-white;
	
	.contentMenuArea {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: 950px;
		height: 37px;
		
		.contentMenu {
			float: left;
			width: 33.333%;
			text-align: center;
			padding: 12px 0;
			font-size: @font-lagesize;
		}
		
		.menuUnderbar {
			position: relative;
			bottom: -34px;
			width: 316.666px;
			height: 3px;
			background-color: @main-red;
		}
		
		.menuUnderbar1 {
			left: 0px;
			transtion: 0.7s left ease;
			-webkit-transition: 0.7s left ease;
		}
		
		.menuUnderbar2 {
			left: 316.666px;
			transtion: 0.7s left ease;
			-webkit-transition: 0.7s left ease;
		}
		
		.menuUnderbar3 {
			left: 633.333px;
			transtion: 0.7s left ease;
			-webkit-transition: 0.7s left ease;
		}
	}
	
	.active1 {
		&:hover {
			.menuUnderbar {
				left: 0;
			}
		}
	}
	
	.active2 {
		&:hover {
			.menuUnderbar {
				left: 316.666px;
			}
		}
	}
	
	.active3 {
		&:hover {
			.menuUnderbar {
				left: 633.333px;
			}
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.biz_contentsMenu {
		.contentMenuArea {
			width: 650px;
			
			.menuUnderbar {
				width: 216.666px;
			}
			
			.menuUnderbar2 {
				left: 216.666px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}
			
			.menuUnderbar3 {
				left: 433.333px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}
		}
		
		.active1 {
			&:hover {
				.menuUnderbar {
					left: 0;
				}
			}
		}
		
		.active2 {
			&:hover {
				.menuUnderbar {
					left: 216.666px;
				}
			}
		}
		
		.active3 {
			&:hover {
				.menuUnderbar {
					left: 433.333px;
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.biz_contentsMenu {
		.contentMenuArea {
			width: 300px;
			
			.menuUnderbar {
				width: 100px;
			}
			
			.menuUnderbar2 {
				left: 100px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}
			
			.menuUnderbar3 {
				left: 200px;
				transtion: 0.7s left ease;
				-webkit-transition: 0.7s left ease;
			}
		}
		
		.active1 {
			&:hover {
				.menuUnderbar {
					left: 0;
				}
			}
		}
		
		.active2 {
			&:hover {
				.menuUnderbar {
					left: 100px;
				}
			}
		}
		
		.active3 {
			&:hover {
				.menuUnderbar {
					left: 200px;
				}
			}
		}
	}
}
</style>
