// インフォメーション用ページ
<template>
	<div id="information-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px"></div>
		<div class="content_area">
			<info-content :filename="$route.params.pn"></info-content>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import gotop from "./cp_gotop.vue"
import infoContent from "./cp_infoContent.vue"
export default {
	name: 'informationPage',
	components: {
		"header-bar": headerBar,
		"info-content": infoContent,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#information-page {
	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/info.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}
	
	.content_area {
		width: 950px;
		margin: 24px auto;
		line-height: 20.9px;
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#information-page {
		.content_area {
			width: 650px;
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#information-page {
		.main_area {
			height: 140px;
		}
		
		.content_area {
			width: 300px;
		}
	}
}
</style>
