<template>
	<div id="company-philosophy-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<comp-menus :activeNode="code"></comp-menus>
		</div>
		<div class="content_area">
			<div class="content_header philosophy_header">
				<div class="content_header_text">
					<div class="bigMessage">経営理念</div>
					<br>
					<div>私たちは以下の理念に従い行動します</div>
				</div>
			</div>
			<div class="philosophy_detail">
				<section>
					<table align="center">
						<tr>
							<td class="table_data">
								<div class="table_contents">
									<div style="text-align: center;">
										<span class="table_head">1</span>
									</div>
									<div class="table_body_title oneline">豊かな社会の実現</div>
									<br>
									<div class="table_body_message">私たちは、私たちの事業と一人一人の技術、知恵、そして高い志をもって、より豊かな社会の実現に貢献します</div>
								</div>
							</td>
							<td class="table_data">
								<div class="table_contents">
									<div style="text-align: center;">
										<span class="table_head">2</span>
									</div>
									<div class="table_body_title oneline">お客様満足の追求</div>
									<br>
									<div class="table_body_message">私たちは、常にお客様への感謝の気持ちを忘れず、あらゆるご要望と課題解決に真摯に向き合いお客様の満足を追求します</div>
								</div>
							</td>
						</tr>
						<br>
						<br>
						<tr>
							<td class="table_data">
								<div class="table_contents">
									<div style="text-align: center;">
										<span class="table_head">3</span>
									</div>
									<div class="table_body_title oneline">進取の精神</div>
									<br>
									<div class="table_body_message">私たちは、常に活力があり、新たな価値を作り出す、柔軟で進取の気風を持った集団を目指します</div>
								</div>
							</td>
							<td class="table_data">
								<div class="table_contents">
									<div style="text-align: center;">
										<span class="table_head">4</span>
									</div>
									<div class="table_body_title oneline">社員の成長と幸福の追求</div>
									<br>
									<div class="table_body_message">私たちは、社員の成長と物心両面にわたる幸福を追求します</div>
								</div>
							</td>
						</tr>
					</table>
				</section>
			</div>
			<div id="slogan" class="content_header slogan_header">
				<div class="content_header_text">
					<div class="bigMessage">スローガン</div>
					<br>
					<div>私たちは以下のスローガンを掲げ社会の発展に貢献していきます</div>
				</div>
			</div>
			<div class="slogan_detail">
				<table align="center">
					<tr>
						<td>
						<div class="slogan_text">
						～DATA CREATES THE FUTURE～<br><br>データの力で未来を切り開く
						</div>
						</td>
					</tr>
					<tr>
						<td>
						<div class="slogan_explanation">
						近年、「DX」「ビッグデータ」「AI」「機械学習」といった言葉を頻繁に耳にするようになってきました。
						<br>
						これらの言葉を支える根幹にはある一つのキーワードが存在します。それが「データ」です。
						<br>
						社名が示すとおり、私たちは設立以降、一貫して「データ」の重要性を認識し、システム開発に取り組んでまいりました。
						<br>
						高度な情報化が進む未来に向かい、データの力を活かして、さらなる発展を目指していきます。
						</div>
						</td>
					</tr>
				</table>
			</div>

		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
    </div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import compMenu from "./cp_compMenu.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'companyPhilosophy',
	components: {
		"header-bar": headerBar,
		"comp-menus": compMenu,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	data: function(){
		return {
			code: "2"
		}
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#company-philosophy-page {
	font-size: @font-lagesize;

	table {
		border-collapse: collapse;
		border-spacing: 0;
		font-size: @font-defailtsize;
	}

	.main_area {
		height: 200px;
		background-color: @bgcolor-main;
		/* 画像ファイルの指定 */
		background-image: url(./assets/light.jpg);
		/* 画像を常に天地左右の中央に配置 */
		background-position: center center;
		/* 画像をタイル状に繰り返し表示しない */
		background-repeat: no-repeat;
		/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
		background-size: cover;
	}

	.content_area {
		width: 950px;
		margin: 24px auto;
		line-height: 20.9px;

		.content_header {
			height: 120px;
			background-color: @bgcolor-main;
			/* 画像ファイルの指定 */
			background-image: url(./assets/morningsun.jpg);
			/* 画像を常に天地左右の中央に配置 */
			background-position: bottom center;
			/* 画像をタイル状に繰り返し表示しない */
			background-repeat: no-repeat;
			/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
			background-size: cover;

			text-align: center;
			position: relative;	
			.content_header_text {
				color: @white-textcolor;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				width: 80%;
				height: 3.2rem;
			}
		}

		.philosophy_header {
			/* 画像ファイルの指定 */
			background-image: url(./assets/morningsun.jpg);
		}

		.bigMessage {
			font-size: 250%;
			font-weight: 700;
		}

		.philosophy_detail {
			//margin-top: -20px;
		}

		.table_data {
			display: block;
			float: left;
		}

		.table_contents {
			margin-left: 20px;
			margin-right: 20px;
			background-color: @bgcolor-white;
			width: 420px;
		}

		.table_head {
			font-size: 130%;
			position: relative;
			top: -10px;
			margin-bottom: -10px;
			text-align: center;
			vertical-align: middle;
			background-color: skyblue;
			color: white;
			height: 46px;
			width: 46px;
			line-height: 50px;
			border-radius: 50%;
			display: inline-block;
			border: 3px solid white;
		}

		.table_body_title {
			text-align: center;
			font-size: 200%;
			font-weight: 700;
		}

		.oneline {
			line-height: 5rem;
		}

		.table_body_message {
			text-align: center;
			font-size: @font-lagesize;
			height: 5rem;
			//font-size: 1rem;
		}

		.slogan_header {
			/* 画像ファイルの指定 */
			background-image: url(./assets/ssd.jpg);
		}

		.slogan_detail {
			//height: 20rem;
			text-align: center;

			.slogan_text {
				height: 300%;
				font-size: 200%;
				font-weight: 700;
				margin: 1em;
			}

			.slogan_explanation {
				line-height: 170%;
			}			
		}


		.largesize {
			font-size: @font-lagesize;
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#company-philosophy-page {
		.content_area {
			width: 650px;

			.companyTable {
				width: 650px;
			}
		}

		.company_info_grid {
			.title_cell {
				width: 120px;
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#company-philosophy-page {
		.main_area {
			height: 140px;
		}

		.content_area {
			width: 350px;
			margin: 24px auto;

			.table_contents {
				width: 300px;
			}

			.bigMessage {
				font-size: 200%;
				font-weight: 500;	
			}

			.table_body_title {
				font-size: 170%;
				font-weight: 500;
			}

			.slogan_detail {
				.slogan_text {
					font-size: 150%;
					font-weight: 700;
				}
			}
		}
	}
}
</style>
