<template>
	<div id="product-page">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<div class="head_image"></div>
			<div class="contentArea">
				<div>
					<div class="head_title bold_title">主要製品</div>
					<div class="sub_title bold_title" style="margin-bottom:16px">
						私たちは主に医療分野において、さまざまな角度から業務の効率化を実現するソリューションをご提案します。
					</div>
				</div>
				<div class="product_area cresc_area">
					<div class="image_area">
						<router-link to="/business/cresc"><img class="image" src="./assets/cresc_top2.jpg"></router-link>
					</div>
					<div class="text_area">
						<div class="product_title"><i class="fa-solid fa-play fa-fw"></i>電子カルテ『CRESC（クレス）』</div>
						<div>CRESCは、医療用データベースとして定評のあるInterSystems Cach&eacute;<sup>&reg;</sup>をデータベースに採用した、中規模病院向け電子カルテです。</div>
						<router-link to="/business/cresc" class="btn btn--orange btn-c product_btn">CRESCの詳細はこちら</router-link>
					</div>
				</div>
				<div class="product_area medlabo_area">
					<div class="text_area">
						<div class="product_title"><i class="fa-solid fa-play fa-fw"></i>検査システム『med@LABO』</div>
						<div>med@LABO（ラボ）は、検査科の業務効率向上はもとより病院内の職員・患者様においても有意義な機能を満載したシステムです。<br>
						シンプルで見読性に優れた画面と充実した機能をご提供致します。</div>
						<router-link to="/business/medlabo" class="btn btn--orange btn-c product_btn">med@LABOの詳細はこちら</router-link>
					</div>
					<div class="image_area">
						<router-link to="/business/medlabo"><img class="image" src="./assets/medlabo_top2.jpg"></router-link>
					</div>
				</div>
				<div class="product_area warabee_area">
					<div class="image_area">
						<router-link to="/business/warabee"><img class="image" src="./assets/warabee_top2.jpg"></router-link>
					</div>
					<div class="text_area">
						<div class="product_title"><i class="fa-solid fa-play fa-fw"></i>不妊治療システム『Warabee』</div>
						<div>患者様一人ひとりの月経周期に合わせたカレンダーに沿って時系列で治療計画を作成し、診療行為を入力していくことで、不妊治に特化した療業務プロセスの効率化とデータ管理を行うシステムです。</div>
						<router-link to="/business/warabee" class="btn btn--orange btn-c product_btn">Warabeeの詳細はこちら</router-link>
					</div>
				</div>
				<div class="product_area medicalcube_area">
					<div class="text_area">
						<div class="product_title"><i class="fa-solid fa-play fa-fw"></i>診療支援DWH『MedicalCube』</div>
						<div>MedicalCubeは、電子カルテや各種病院システムから取得した情報を、ベンダーフリーな高性能リポジトリに構築し、そのデータの二次活用として、診療・研究・教育の支援、障害時対応・支援、地域連携支援をご提供するシステムです。</div>
						<router-link to="/business/medcube" class="btn btn--orange btn-c product_btn">MedicalCubeの詳細はこちら</router-link>
					</div>
					<div class="image_area">
						<router-link to="/business/medcube"><img class="image" src="./assets/medcube_top2.jpg"></router-link>
					</div>
				</div>
				<div class="product_area tanom_area">
					<div class="image_area">
						<router-link to="/business/tanom"><img class="image" src="./assets/tanom_top.png"></router-link>
					</div>
					<div class="text_area">
						<div class="product_title"><i class="fa-solid fa-play fa-fw"></i>簡易受発注システム『TANOM』</div>
						<div>コピー用紙やボールペンなどの文房具、ウェットティッシュなどの衛生用品といった備品・用度品の購入は紙で運用していませんか。『TANOM』なら、これら備品・用度品の請求・受発注をシステムで手軽に管理することができます。</div>
						<router-link to="/business/tanom" class="btn btn--orange btn-c product_btn">TANOMの詳細はこちら</router-link>
					</div>
				</div>
				<div class="casestudy">
					<router-link to="/business/casestudy" class="btn btn--green btn-c casestudy_btn">導入事例はこちら</router-link>
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import productSlide from "./cp_businessSlide.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'businessPage',
	components: {
		"header-bar": headerBar,
		"product-slide": productSlide,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	data: function(){
		return {
			isSmartPhone: false
		}
	},
	methods: {
		handleResize: function(e) {
			if (window.matchMedia('(max-width:480px)').matches) {
				// SPの処理
				this.isSmartPhone = true;
			} else if (window.matchMedia('(max-width:800px)').matches) {
				// TABの処理
				this.isSmartPhone = false;
			} else {
				// PCの処理
				this.isSmartPhone = false;
			}
		}
	},
	created: function(){
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('load', (e) => {
			this.handleResize(e);
		});
	},
	mounted: function(){
		document.body.classList.remove("body");
	}
}

</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
#product-page {	
	.main_area {
		.head_image {
			height: 200px;
			background-color: @bgcolor-main;
			/* 画像ファイルの指定 */
			background-image: url(./assets/product.jpg);
			/* 画像を常に天地左右の中央に配置 */
			background-position: center center;
			/* 画像をタイル状に繰り返し表示しない */
			background-repeat: no-repeat;
			/* 表示するコンテナの大きさに基づいて、背景画像を調整 */
			background-size: cover;
		}
		.contentArea {
			width: 950px;
			margin: 22px auto 20px;
			
			.tbl {
				border-collapse: separate;
				border-spacing: 5px 20px;
			}
			.conceptComment {
				border: 1px solid #C1C1C1;
				border-radius: 16px;
				padding: 16px;
				box-shadow: 3px 3px 8px 2px rgba(0,0,0,0.1);
				margin-bottom: 32px;
				line-height: 20.9px;
			}
			
			.head_title {
				.section_bar;
				.sub_title;
				padding: 8px;
				margin-bottom: 12px;
			}
			
			.bix_sub_title {
				margin-bottom: 8px;
			}
			
			.comment {
				margin-top: 18px;
				font-size: @font-sssize;
			}

			.product_area {
				margin: 50px 0;
				display: flex;
			}

			.image_area {
				margin: 0 12.5px;
			}

			.image {
				width: 450px;
				height: auto;
			}

			.text_area {
				margin: 0 12.5px;
				width: 450px;
				display: flex;
				flex-direction: column;
			}

			.product_title {
				padding: 0.5em;
				font-size: 1.9em;
				font-weight: bold;
			}

			.product_btn {
				margin-top: auto;
				width: 250px;
			}

			.casestudy {
				margin-top: 3.0em;
				display: flex;
				justify-content: center;
			}

			.casestudy_btn {
				padding-left: 5.0em;
				padding-right: 5.0em;
			}
		}
	}
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	#product-page {
		.main_area {
			.contentArea {
				width: 650px;

				.product_area {
					margin: 50px 0;
					display: flex;
				}

				.image {
					width: 300px;
					height: auto;
				}

				.text_area {
					width: 300px;
					display: flex;
					flex-direction: column;
				}

				.product_title {
					font-size: 1.6em;
					font-weight: bold;
				}

				.product_btn {
					margin-top: 20px;
				}
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	#product-page {
		.main_area {
			.contentArea {
				width: 300px;

				.product_area {
					margin: 50px 0;
					display: flex;
				}

				.medlabo_area,
				.medicalcube_area {
					flex-direction: column-reverse;
				}

				.cresc_area,
				.warabee_area,
				.tanom_area {
					flex-direction: column;
				}

				.image_area {
					margin: 0 12.5px;
				}

				.image {
					width: 300px;
					height: auto;
				}

				.text_area {
					width: 300px;
					display: flex;
					flex-direction: column;
				}

				.product_title {
					font-size: 1.6em;
					font-weight: bold;
				}

				.product_btn {
					margin-top: 20px;
				}

			}
		}
	}
}
</style>
