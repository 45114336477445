import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

const state = {
	// 導入先紹介ページの表示可否フラグ
	casestudy: true,
	// 問い合わせの利用可否フラグ
	contact: true
}
const actions = {}
const getters = {}
const mutations = {}

export default new Vuex.Store({
	state,
	getters,
	actions,
	mutations
})
