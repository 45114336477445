// CRESC紹介ページ
<template>
	<div class="busi-cresc">
		<div>
			<header-bar></header-bar>
		</div>
		<div class="main_area" style="margin-top:76px">
			<div class="navi_area"><span class="navi_bar_link"><router-link to="/">TOP</router-link> &gt; <router-link to="/product">PRODUCT</router-link> &gt;</span> CRESC</div>
			<div class="bgImage_area"></div>
			<div class="conetnts_area">
				<biz-menu></biz-menu>
				<div class="description">
					CRESC（クレス）は、様々な病院様に育てて頂いたシステムです。<br>
					貴院のニーズにお応え出来るソリューションをご提案致します。
				</div>
				<a name="outline" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div>
					CRESCは、医療用データベースとして定評のあるInterSystems Cach&eacute;<sup>&reg;</sup>をデータベースに採用した、中規模病院向け電子カルテです。
				</div>
				<a name="function" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div class="sub_title">■電子カルテ／オーダリング機能</div>
				<div class="tbl" style="width:100%">
					<div class="tblrow">
						<div class="tblcell" style="text-align:center; position:relative">
							<transition name="trBalloon"><div class="balloon balloonfont balloon1" v-show="blshow1" style="background-color:#95FB9D; padding:0">
								<div style="position:absolute; left:12px;">オーダー種毎に色分けされた<br>見やすい履歴表示</div>
							</div></transition>
							<transition name="trBalloon"><div class="balloon balloonfont balloon2" v-show="blshow1" style="background-color:#95FB9D; padding:0">
								<div style="position:absolute; left:12px;">見たい情報がすぐに見られる<br>参照エリア</div>
							</div></transition>
							<transition name="trBalloon"><div class="balloon balloonfont balloon3" v-show="blshow1" style="background-color:#95FB9D; padding:0">
								<div style="position:absolute; left:9px;">PACSやシェーマ画像等を<br>ドラッグ＆ドロップで簡単に添付出来る<br>など使いやすい作成エリア</div>
							</div></transition>
							<img class="img1" src="./assets/cresc_1.png" />
						</div>
					</div>
				</div>
				<div class="sub_title" style="margin-top:340px; text-align:right">■看護支援機能</div>
				<div class="tbl" style="width:100%; margin-top:32px;">
					<div class="tblrow">
						<div class="tblcell" style="position:relative">
							<img class="img2" src="./assets/cresc_2.png" />
							<div class="largesize disc1">
								看護必要度や看護計画など看護師の日次業務をサポートする事で、患者様への本来の看護に、より多くの時間を充てる事が出来す。<br><br>
								看護オーダ、バイタル入力、経過表、看護計画、看護サマリ、勤務表作成、様式９統計など多くの機能をご提供致します。
							</div>
						</div>
					</div>
				</div>
				<div class="sub_title" style="margin-top:86px;">■クリニカルパス機能</div>
				<div class="tbl" style="width:100%">
					<div class="tblrow">
						<div class="tblcell" style="text-align:right; position:relative">
							<img class="img3" src="./assets/cresc_3.png" />
							<div class="largesize disc2">
								クリニカルビューから、オーダやバイタル入力、文書記載の予定登録など、多くの機能を直接利用することが出来ます。
							</div>
							<transition name="trBalloon2"><div class="balloon balloonfont balloon4" v-show="blshow2" style="text-align:center; background-color:#FBCEF3; padding:0">
								<div style="position:absolute; left:12px;">グラフィカルで直感的な<br>表示と操作性</div>
							</div></transition>
							<transition name="trBalloon2"><div class="balloon balloonfont balloon5" v-show="blshow2" style="text-align:center; background-color:#FBCEF3; padding:0">
								<div style="position:absolute; left:18px;">患者の変更も<br>スムーズ</div>
							</div></transition>
						</div>
					</div>
				</div>
				<div class="sub_title subt3" style="text-align:right">■モバイル機能</div>
				<div class="tbl" style="width:100%; margin:32px 0;">
					<div class="tblrow">
						<div class="tblcell" style="position:relative; text-align:center;">
							<div class="largesize disc3" style="text-align:left; line-height:20.9px">
								バーコードリーダー（Bluetooth、モバイルカメラ）を利用し、様々な認証が可能です。
								認証を簡便化する事により、医療ミスを極限まで減らす事ができます。
								また、タブレット等を利用しベッドサイドでのバイタル入力も可能です。
							</div>
							<img class="contenImg1" src="./assets/cresc_4.png" />
						</div>
					</div>
				</div>
				<div class="tbl" style="width:100%; margin:64px 0;">
					<div class="tblrow">
						<div class="tblcell largesize" style="line-height:20.9px">
							これらの他にも、各部門受け機能や、文書管理、予約機能など多くの機能をご提供致します。
						</div>
					</div>
				</div>
				<a name="request" style="position:relative; top:-40px"></a>
				<div style="position:relative; height:40px"></div>
				<div class="request_area">
					<div class="titlesize">資料請求</div>
					<div style="margin-top:32px">病院情報システム CRESC に関する資料のお求めや、ご質問等につきましては、以下よりお気軽にお問い合わせ下さい。</div>
					<div class="button_style titlesize" style="margin-top:32px"><router-link to="/contact?flg=1">資料請求はこちら</router-link></div>
				</div>
			</div>
		</div>
		<div class="footerArea">
			<site-map></site-map>
			<copy-right></copy-right>
		</div>
		<gotop-button></gotop-button>
	</div>
</template>

<script>
import headerBar from "./cp_header_bar.vue"
import sitemap from "./cp_site_map.vue"
import copyright from "./cp_copy_right.vue"
import bizMenu from "./cp_businessMenu.vue"
import gotop from "./cp_gotop.vue"
export default {
	name: 'busiCRESCPage',
	components: {
		"header-bar": headerBar,
		"biz-menu": bizMenu,
		"site-map": sitemap,
		"copy-right": copyright,
		"gotop-button": gotop
	},
	data: function(){
		return {
			scrollY: 0,
			height: 0,
			blshow1: false,
			blshow2: false
		}
	},
	methods: {
		handleScroll: function(e){
			this.scrollY = window.pageYOffset;
			
			if ((this.scrollY + this.height) > 900) {
				this.blshow1 = true;
			}
			if ((this.scrollY + this.height) > 2050) {
				this.blshow2 = true;
			}
		},
		handleResize: function(e){
			this.height = document.documentElement.clientHeight;
		}
	},
	created: function(){
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleResize);
	},
	mounted: function(){
		document.body.classList.remove("body");
		this.handleResize();
		this.handleScroll();
	}
}
</script>

<style lang="less">
@import "./less/reset.less";
@import "./less/default.less";
.busi-cresc {
	.navi_area {
		width: 950px;
		margin: 22px auto 16px;
	}

	.bgImage_area {
		height: 160px;
		background-image: url(./assets/cresc_top2.jpg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.conetnts_area {
		width: 950px;
		margin: 22px auto;
		
		.description {
			text-align: center;
			font-size: @font-titlesize;
			line-height: 23px;
			margin: 32px 0;
		}
		
		.sub_title {
			font-weight: bold;
			font-size: @font-titlesize;
			margin-top: 64px;
		}
		
		.subt3 {
			margin-top: 180px;
		}
		
		.titlesize {
			font-size: @font-titlesize;
		}
		
		.largesize {
			font-size: @font-lagesize;
		}
		
		.balloonfont {
			font-size: @font-lagesize;
		}
		
		.contenImg1 {
		}
		
		.disc1 {
			position: absolute;
			top: 80px;
			left: 520px;
			width: 400px;
			line-height:20.9px
		}
		
		.disc2 {
			position: absolute;
			top: 80px;
			left: 60px;
			text-align: left;
			width: 400px;
			line-height:20.9px;
		}
		
		.disc3 {
			width: 600px; 
		}
		
		.balloon1 {
			top: 60px;
			border-radius: 210px;
			width: 210px;
			height: 210px;
			
			div {
				top: 88px;
			}
		}
		
		.balloon2 {
			top: 300px;
			left: 380px;
			border-radius: 210px;
			width: 210px;
			height: 210px;
			
			div {
				top: 88px;
			}
		}
		
		.balloon3 {
			top: 120px;
			left: 700px;
			border-radius: 280px;
			width: 280px;
			height: 280px;
			
			div {
				top: 112px;
			}
		}
		
		.balloon4 {
			top: 160px;
			left: 240px;
			border-radius: 180px;
			width: 180px;
			height: 180px;
			
			div {
				top: 76px;
			}
		}
		
		.balloon5 {
			top: 310px;
			left: 600px;
			border-radius: 120px;
			width: 120px;
			height: 120px;
			
			div {
				top:46px;
			}
		}
		
		.request_area {
			margin: 64px;
			text-align: center;
		}
	}
}

.trBalloon-enter-active, .trBalloon-leave-active {
	transition: all 1s ease;
	-webkit-transition: all 1s ease;
}
.trBalloon-enter, .trBalloon-leave-to {
	opacity: 0;
}
.trBalloon2-enter-active, .trBalloon2-leave-active {
	transition: all 1s ease;
	-webkit-transition: all 1s ease;
}
.trBalloon2-enter, .trBalloon2-leave-to {
	opacity: 0;
}

/* スマホ横、タブレット縦 */
@media screen and (max-width: 800px) {
	.busi-cresc {
		.navi_area {
			width: 650px;
		}
		
		.bgImage_area {
			height: 103px;
		}
		
		.conetnts_area {
			width: 650px;
			
			.contenImg1 {
				width: 600px;
				height: 375px;
			}
			
			.disc1 {
				top: 170px;
				left: 360px;
				width: 300px;
			}
			
			.balloon2 {
				top: 300px;
				left: 160px;
			}
			
			.balloon3 {
				top: 120px;
				left: 380px;
			}
			
			.balloon4 {
				top: 160px;
				left: 80px;
			}
			
			.balloon5 {
				top: 310px;
				left: 360px;
			}
		}
	}
}
/* スマホ縦 */
@media screen and (max-width: 480px) {
	.busi-cresc {
		.navi_area {
			width: 300px;
			margin-top: 90px;
		}
		
		.bgImage_area {
			height: 103px;
		}
		
		.conetnts_area {
			width: 300px;
			
			.balloonfont {
				font-size: @font-sssize;
			}
			
			.subt3 {
				margin-top: 120px;
			}
			
			.img1 {
				width: 300px;
				height: 227px;
			}
			
			.img2 {
				width: 300px;
				height: 216.6px;
			}
			
			.img3 {
				width: 300px;
				height: 252.9px;
				margin-top: 74px;
			}
			
			.contenImg1 {
				width: 300px;
				height: 187.5px;
			}
			
			.disc1 {
				top: 180px;
				left: 0;
				width: 300px;
				font-size: @font-sssize;
				line-height: 14px
			}
			
			.disc2 {
				position: absolute;
				top: 20px;
				left: 0;
				text-align: left;
				width: 300px;
				line-height:20.9px;
			}
			
			.disc3 {
				width: 300px; 
			}
			
			.balloon1 {
				top: 180px;
				border-radius: 160px;
				width: 160px;
				height: 160px;
				
				div {
					top: 64px;
				}
			}
			
			.balloon2 {
				top: 230px;
				left: 140px;
				border-radius: 160px;
				width: 160px;
				height: 160px;
				
				div {
					top: 64px;
				}
			}
			
			.balloon3 {
				top: 330px;
				left: 20px;
				border-radius: 210px;
				width: 210px;
				height: 210px;
				
				div {
					top: 78px;
				}
			}
			
			.balloon4 {
				top: 240px;
				left: 0;
				border-radius: 138px;
				width: 138px;
				height: 138px;
				
				div {
					top: 52px;
				}
			}
			
			.balloon5 {
				top: 310px;
				left: 172px;
				border-radius: 94px;
				width: 94px;
				height: 94px;
				
				div {
					top:30px;
				}
			}
			
			.request_area {
				margin: 64px 0;
				text-align: center;
			}
		}
	}
}
</style>
